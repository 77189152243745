<template>
  <vue-if-bot>
    <div class="contact__container">
      <div data-aos="fade-up" class="contact__map">
        <div class="text__info">
          <strong>NenConsulting IT and Security GmbH</strong>
          <p>Mühlenstrаße 8a</p>
          <p>14167 Berlin</p>
          <p>{{ $t("impressum.country") }}</p>
           <p>{{ $t("contact.phone") }}</p>
          <br />
          <br />
          <div class="address">
            <div class="el">
              <img src="@/assets/img/email.png" alt="icon" />
              <p>
                <a href="mailto: office@itandsec.com"> office@itandsec.com</a>
              </p>
            </div>
            <div class="el">
              <img src="@/assets/img/globe.png" alt="icon" />
              <p>www.itandsec.com</p>
            </div>
            <div class="el">
              <img src="@/assets/img/clock.png" alt="icon" />
              <p>{{ $t("contact.day") }}</p>
            </div>

            <p>{{ $t("contact.day-01") }}</p>
            <p>{{ $t("contact.day-02") }}</p>
          </div>
        </div>
        <div data-aos="fade-up" class="map__info">
          <GmapMap
            :center="{ lat: 52.43009783011551, lng: 13.262368855338735 }"
            :zoom="16"
            map-type-id="terrain"
            style="width: 715px; height: 450px"
          >
            <gmap-custom-marker :marker="marker.marker2">
              <a target="_blank" href="https://goo.gl/maps/CzaUowxvQV8akuLu9"
                ><img class="image_marker" src="@/assets/img/flag.png"
              /></a>
            </gmap-custom-marker>
          </GmapMap>
        </div>
      </div>
      <div data-aos="fade-up" class="contact__map">
        <div class="text__info">
          <strong>NenConsulting IT and Security GmbH</strong>
          <p>Neutorstrasse 9b</p>
          <p>61250 Usingen</p>
          <p>{{ $t("impressum.country") }}</p>
          <p>{{ $t("contact.phone") }}</p>
          <br />
          <br />
          <div class="address">
            <div class="el">
              <img src="@/assets/img/email.png" alt="icon" />
              <p>
                <a href="mailto: office@itandsec.com"> office@itandsec.com</a>
              </p>
            </div>
            <div class="el">
              <img src="@/assets/img/globe.png" alt="icon" />
              <p>www.itandsec.com</p>
            </div>
            <div class="el">
              <img src="@/assets/img/clock.png" alt="icon" />
              <p>Monday - Friday: 9:00 → 18:00</p>
            </div>

            <p>{{ $t("contact.day-01") }}</p>
            <p>{{ $t("contact.day-02") }}</p>
          </div>
        </div>
        <div data-aos="fade-up" class="map__info">
          <GmapMap
            :center="{ lat: 50.33579, lng: 8.53227 }"
            :zoom="16"
            map-type-id="terrain"
            style="width: 715px; height: 450px"
          >
            <gmap-custom-marker :marker="marker.marker1">
              <a target="_blank" href="https://goo.gl/maps/zDYveQN5QgRruXFD6"
                ><img class="image_marker" src="@/assets/img/flag.png"
              /></a>
            </gmap-custom-marker>
          </GmapMap>
        </div>
      </div>
    </div>
  </vue-if-bot>
</template>

<script>
import GmapCustomMarker from "vue2-gmap-custom-marker";
export default {
  components: {
    "gmap-custom-marker": GmapCustomMarker,
     VueIfBot: () => import('vue-if-bot'),
  },
  data() {
    return {
      marker: {
        marker1: {
          lat: 50.33579,
          lng: 8.53227,
        },
        marker2: {
          lat: 52.43010,
          lng: 13.26179,
        },
      },
    };
  },
};
</script>

<style lang="scss">
.contact__container {
  .contact__map {
    padding: 0 194px 0 136px;
    display: flex;
    justify-content: space-between;
    margin: 20px 0;

    .text__info {
      display: flex;
      justify-content: flex-start;
      flex-direction: column;

      .address {
        display: flex;
        flex-direction: column;
        .el {
          display: flex;
          margin-bottom: 10px;

          img {
            width: 20px;
            height: 20px;
            margin-right: 10px;
          }
        }
      }

      p {
        font-size: 16px;
      }

      strong {
        font-size: 18px;
        font-weight: 600;
        padding-bottom: 20px;
      }
    }

    .map__info {
      flex-basis: 60%;
      width: 500px;
      height: 500px;

      .image_marker {
        width: 50px;
        height: 50px;
      }
    }

    @media (max-width: 1140px) {
      .map__info {
        flex-basis: 100%;
        max-width: 100%;
        height: 200px;
        padding: 20px;
        justify-content: center;
        .vue-map-container {
          max-width: 100%;
          height: 200px;
        }
      }
    }
  }

  @media (max-width: 1140px) {
    .contact__map {
      padding: 20px;
      flex-direction: column;
    }
  }
}
</style>