import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import About from '../views/About.vue'
import Services from '../views/Services.vue'
import Trainings from '../views/Trainings.vue'
import Solutions from '../views/Solutions.vue'
import References from '../views/References.vue'
import Contact from '../views/Contact.vue'
import Philosophy from '../views/Philosophy.vue'
import Data_Protection from '../views/Data_Protection.vue'
import Impressum from '../views/Impressum.vue'
import Job from '../views/Job.vue'
import Blog from '../views/Blog.vue'

Vue.use(VueRouter)

const routes = [{
  path: '/',
  name: 'Home',
  component: Home
},
{
  path: '/about',
  name: 'About',
  component: About
},
{
  path: '/services',
  name: 'Services',
  component: Services
},
{
  path: '/trainings',
  name: 'Trainings',
  component: Trainings
},
{
  path: '/solutions',
  name: 'Solutions',
  component: Solutions
},
{
  path: '/references',
  name: 'References',
  component: References
},
{
  path: '/contact',
  name: 'Contact',
  component: Contact
}, 
{
  path: '/philosophy',
  name: 'Philosophy',
  component: Philosophy
},
{
  path: '/data_protection',
  name: 'Data_Protection',
  component: Data_Protection
},
{
  path: '/impressum',
  name: 'Impressum',
  component: Impressum
},
{
  path: '/job',
  name: 'Job',
  component: Job
},
{
  path: '/blog',
  name: 'Blog',
  component: Blog
},
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  linkActiveClass: "active",
  linkExactActiveClass: "exact-active",
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
})

export default router