<template>
    <div class="tr__container">
      <vue-if-bot>
      <div data-aos="flip-up" class="tr__img">
        <div class="tr__inside__el">
          <img src="@/assets/img/logo.png" alt="logo" />
          <p data-aos="zoom-in-down" class="text">
            {{ $t("trainings.description") }}
          </p>
        </div>
      </div>
      <div class="tr__text">
        <p>{{ $t("trainings.offer") }}</p>
      </div>
      <div class="tr__offers">
        <h3>{{ $t("trainings.offer-title") }}</h3>
        <h5>{{ $t("trainings.offer-title-01") }}</h5>
        <div class="tr__offers_div">
          <section>
            <h5>{{ $t("trainings.offer-title-02") }}</h5>
            <div class="offer">
              <div>
                <span>
                  <svg class="icon" viewBox="0 0 512 512" id="svg-ca44">
                    <path
                      d="m433.1 67.1-231.8 231.9c-6.2 6.2-16.4 6.2-22.6 0l-99.8-99.8-78.9 78.8 150.5 150.5c10.5 10.5 24.6 16.3 39.4 16.3 14.8 0 29-5.9 39.4-16.3l282.7-282.5z"
                      fill="#17477d"
                    ></path></svg
                ></span>
                <p>{{ $t("trainings.offer-01") }}</p>
              </div>
              <div>
                <span>
                  <svg class="icon" viewBox="0 0 512 512" id="svg-ca44">
                    <path
                      d="m433.1 67.1-231.8 231.9c-6.2 6.2-16.4 6.2-22.6 0l-99.8-99.8-78.9 78.8 150.5 150.5c10.5 10.5 24.6 16.3 39.4 16.3 14.8 0 29-5.9 39.4-16.3l282.7-282.5z"
                      fill="#17477d"
                    ></path></svg
                ></span>
                <p>{{ $t("trainings.offer-02") }}</p>
              </div>
              <div>
                <span>
                  <svg class="icon" viewBox="0 0 512 512" id="svg-ca44">
                    <path
                      d="m433.1 67.1-231.8 231.9c-6.2 6.2-16.4 6.2-22.6 0l-99.8-99.8-78.9 78.8 150.5 150.5c10.5 10.5 24.6 16.3 39.4 16.3 14.8 0 29-5.9 39.4-16.3l282.7-282.5z"
                      fill="#17477d"
                    ></path></svg
                ></span>
                <p>{{ $t("trainings.offer-03") }}</p>
              </div>
              <div>
                <span>
                  <svg class="icon" viewBox="0 0 512 512" id="svg-ca44">
                    <path
                      d="m433.1 67.1-231.8 231.9c-6.2 6.2-16.4 6.2-22.6 0l-99.8-99.8-78.9 78.8 150.5 150.5c10.5 10.5 24.6 16.3 39.4 16.3 14.8 0 29-5.9 39.4-16.3l282.7-282.5z"
                      fill="#17477d"
                    ></path></svg
                ></span>
                <p>{{ $t("trainings.offer-04") }}</p>
              </div>
            </div>
          </section>
          <section>
            <h5>{{ $t("trainings.offer-title-03") }}</h5>
            <div class="offer">
              <div>
                <span>
                  <svg class="icon" viewBox="0 0 512 512" id="svg-ca44">
                    <path
                      d="m433.1 67.1-231.8 231.9c-6.2 6.2-16.4 6.2-22.6 0l-99.8-99.8-78.9 78.8 150.5 150.5c10.5 10.5 24.6 16.3 39.4 16.3 14.8 0 29-5.9 39.4-16.3l282.7-282.5z"
                      fill="#17477d"
                    ></path></svg
                ></span>
                <p>{{ $t("trainings.offer-05") }}</p>
              </div>
              <div>
                <span>
                  <svg class="icon" viewBox="0 0 512 512" id="svg-ca44">
                    <path
                      d="m433.1 67.1-231.8 231.9c-6.2 6.2-16.4 6.2-22.6 0l-99.8-99.8-78.9 78.8 150.5 150.5c10.5 10.5 24.6 16.3 39.4 16.3 14.8 0 29-5.9 39.4-16.3l282.7-282.5z"
                      fill="#17477d"
                    ></path></svg
                ></span>
                <p>{{ $t("trainings.offer-06") }}</p>
              </div>
              <div>
                <span>
                  <svg class="icon" viewBox="0 0 512 512" id="svg-ca44">
                    <path
                      d="m433.1 67.1-231.8 231.9c-6.2 6.2-16.4 6.2-22.6 0l-99.8-99.8-78.9 78.8 150.5 150.5c10.5 10.5 24.6 16.3 39.4 16.3 14.8 0 29-5.9 39.4-16.3l282.7-282.5z"
                      fill="#17477d"
                    ></path></svg
                ></span>
                <p>{{ $t("trainings.offer-07") }}</p>
              </div>
              <div>
                <span>
                  <svg class="icon" viewBox="0 0 512 512" id="svg-ca44">
                    <path
                      d="m433.1 67.1-231.8 231.9c-6.2 6.2-16.4 6.2-22.6 0l-99.8-99.8-78.9 78.8 150.5 150.5c10.5 10.5 24.6 16.3 39.4 16.3 14.8 0 29-5.9 39.4-16.3l282.7-282.5z"
                      fill="#17477d"
                    ></path></svg
                ></span>
                <p>{{ $t("trainings.offer-08") }}</p>
              </div>
            </div>
          </section>
          <section>
            <h5>{{ $t("trainings.offer-title-04") }}</h5>
            <div class="offer">
              <div>
                <span>
                  <svg class="icon" viewBox="0 0 512 512" id="svg-ca44">
                    <path
                      d="m433.1 67.1-231.8 231.9c-6.2 6.2-16.4 6.2-22.6 0l-99.8-99.8-78.9 78.8 150.5 150.5c10.5 10.5 24.6 16.3 39.4 16.3 14.8 0 29-5.9 39.4-16.3l282.7-282.5z"
                      fill="#17477d"
                    ></path></svg
                ></span>
                <p>{{ $t("trainings.offer-09") }}</p>
              </div>
              <div>
                <span>
                  <svg class="icon" viewBox="0 0 512 512" id="svg-ca44">
                    <path
                      d="m433.1 67.1-231.8 231.9c-6.2 6.2-16.4 6.2-22.6 0l-99.8-99.8-78.9 78.8 150.5 150.5c10.5 10.5 24.6 16.3 39.4 16.3 14.8 0 29-5.9 39.4-16.3l282.7-282.5z"
                      fill="#17477d"
                    ></path></svg
                ></span>
                <p>{{ $t("trainings.offer-10") }}</p>
              </div>
              <div>
                <span>
                  <svg class="icon" viewBox="0 0 512 512" id="svg-ca44">
                    <path
                      d="m433.1 67.1-231.8 231.9c-6.2 6.2-16.4 6.2-22.6 0l-99.8-99.8-78.9 78.8 150.5 150.5c10.5 10.5 24.6 16.3 39.4 16.3 14.8 0 29-5.9 39.4-16.3l282.7-282.5z"
                      fill="#17477d"
                    ></path></svg
                ></span>
                <p>{{ $t("trainings.offer-11") }}</p>
              </div>
            </div>
          </section>
        </div>
      </div>

      <div class="tr__offers second" >
        <h3>{{ $t("trainings.offer-title-05") }}</h3>
        <h5>{{ $t("trainings.offer-title-06") }}</h5>
        <p class="des">{{ $t("trainings.offer-12") }}</p>
        <p class="des">{{ $t("trainings.offer-13") }}</p>

        <div class="tr__offers_div">
          <section>
            <h5>{{ $t("trainings.offer-title-02") }}</h5>
            <div class="offer">
              <div>
                <span>
                  <svg class="icon" viewBox="0 0 512 512" id="svg-ca44">
                    <path
                      d="m433.1 67.1-231.8 231.9c-6.2 6.2-16.4 6.2-22.6 0l-99.8-99.8-78.9 78.8 150.5 150.5c10.5 10.5 24.6 16.3 39.4 16.3 14.8 0 29-5.9 39.4-16.3l282.7-282.5z"
                      fill="#17477d"
                    ></path></svg
                ></span>
                <p>{{ $t("trainings.offer-14") }}</p>
              </div>
              <div>
                <span>
                  <svg class="icon" viewBox="0 0 512 512" id="svg-ca44">
                    <path
                      d="m433.1 67.1-231.8 231.9c-6.2 6.2-16.4 6.2-22.6 0l-99.8-99.8-78.9 78.8 150.5 150.5c10.5 10.5 24.6 16.3 39.4 16.3 14.8 0 29-5.9 39.4-16.3l282.7-282.5z"
                      fill="#17477d"
                    ></path></svg
                ></span>
                <p>{{ $t("trainings.offer-15") }}</p>
              </div>
              <div>
                <span>
                  <svg class="icon" viewBox="0 0 512 512" id="svg-ca44">
                    <path
                      d="m433.1 67.1-231.8 231.9c-6.2 6.2-16.4 6.2-22.6 0l-99.8-99.8-78.9 78.8 150.5 150.5c10.5 10.5 24.6 16.3 39.4 16.3 14.8 0 29-5.9 39.4-16.3l282.7-282.5z"
                      fill="#17477d"
                    ></path></svg
                ></span>
                <p>{{ $t("trainings.offer-16") }}</p>
              </div>
              <div>
                <span>
                  <svg class="icon" viewBox="0 0 512 512" id="svg-ca44">
                    <path
                      d="m433.1 67.1-231.8 231.9c-6.2 6.2-16.4 6.2-22.6 0l-99.8-99.8-78.9 78.8 150.5 150.5c10.5 10.5 24.6 16.3 39.4 16.3 14.8 0 29-5.9 39.4-16.3l282.7-282.5z"
                      fill="#17477d"
                    ></path></svg
                ></span>
                <p>{{ $t("trainings.offer-17") }}</p>
              </div>
              <div>
                <span>
                  <svg class="icon" viewBox="0 0 512 512" id="svg-ca44">
                    <path
                      d="m433.1 67.1-231.8 231.9c-6.2 6.2-16.4 6.2-22.6 0l-99.8-99.8-78.9 78.8 150.5 150.5c10.5 10.5 24.6 16.3 39.4 16.3 14.8 0 29-5.9 39.4-16.3l282.7-282.5z"
                      fill="#17477d"
                    ></path></svg
                ></span>
                <p>{{ $t("trainings.offer-18") }}</p>
              </div>
              <div>
                <span>
                  <svg class="icon" viewBox="0 0 512 512" id="svg-ca44">
                    <path
                      d="m433.1 67.1-231.8 231.9c-6.2 6.2-16.4 6.2-22.6 0l-99.8-99.8-78.9 78.8 150.5 150.5c10.5 10.5 24.6 16.3 39.4 16.3 14.8 0 29-5.9 39.4-16.3l282.7-282.5z"
                      fill="#17477d"
                    ></path></svg
                ></span>
                <p>{{ $t("trainings.offer-19") }}</p>
              </div>
              <div>
                <span>
                  <svg class="icon" viewBox="0 0 512 512" id="svg-ca44">
                    <path
                      d="m433.1 67.1-231.8 231.9c-6.2 6.2-16.4 6.2-22.6 0l-99.8-99.8-78.9 78.8 150.5 150.5c10.5 10.5 24.6 16.3 39.4 16.3 14.8 0 29-5.9 39.4-16.3l282.7-282.5z"
                      fill="#17477d"
                    ></path></svg
                ></span>
                <p>{{ $t("trainings.offer-20") }}</p>
              </div>
              <div>
                <span>
                  <svg class="icon" viewBox="0 0 512 512" id="svg-ca44">
                    <path
                      d="m433.1 67.1-231.8 231.9c-6.2 6.2-16.4 6.2-22.6 0l-99.8-99.8-78.9 78.8 150.5 150.5c10.5 10.5 24.6 16.3 39.4 16.3 14.8 0 29-5.9 39.4-16.3l282.7-282.5z"
                      fill="#17477d"
                    ></path></svg
                ></span>
                <p>{{ $t("trainings.offer-21") }}</p>
              </div>
              <div>
                <span>
                  <svg class="icon" viewBox="0 0 512 512" id="svg-ca44">
                    <path
                      d="m433.1 67.1-231.8 231.9c-6.2 6.2-16.4 6.2-22.6 0l-99.8-99.8-78.9 78.8 150.5 150.5c10.5 10.5 24.6 16.3 39.4 16.3 14.8 0 29-5.9 39.4-16.3l282.7-282.5z"
                      fill="#17477d"
                    ></path></svg
                ></span>
                <p>{{ $t("trainings.offer-22") }}</p>
              </div>
              <div>
                <span>
                  <svg class="icon" viewBox="0 0 512 512" id="svg-ca44">
                    <path
                      d="m433.1 67.1-231.8 231.9c-6.2 6.2-16.4 6.2-22.6 0l-99.8-99.8-78.9 78.8 150.5 150.5c10.5 10.5 24.6 16.3 39.4 16.3 14.8 0 29-5.9 39.4-16.3l282.7-282.5z"
                      fill="#17477d"
                    ></path></svg
                ></span>
                <p>{{ $t("trainings.offer-23") }}</p>
              </div>
              <div>
                <span>
                  <svg class="icon" viewBox="0 0 512 512" id="svg-ca44">
                    <path
                      d="m433.1 67.1-231.8 231.9c-6.2 6.2-16.4 6.2-22.6 0l-99.8-99.8-78.9 78.8 150.5 150.5c10.5 10.5 24.6 16.3 39.4 16.3 14.8 0 29-5.9 39.4-16.3l282.7-282.5z"
                      fill="#17477d"
                    ></path></svg
                ></span>
                <p>{{ $t("trainings.offer-24") }}</p>
              </div>
              <div>
                <span>
                  <svg class="icon" viewBox="0 0 512 512" id="svg-ca44">
                    <path
                      d="m433.1 67.1-231.8 231.9c-6.2 6.2-16.4 6.2-22.6 0l-99.8-99.8-78.9 78.8 150.5 150.5c10.5 10.5 24.6 16.3 39.4 16.3 14.8 0 29-5.9 39.4-16.3l282.7-282.5z"
                      fill="#17477d"
                    ></path></svg
                ></span>
                <p>{{ $t("trainings.offer-25") }}</p>
              </div>
              <div>
                <span>
                  <svg class="icon" viewBox="0 0 512 512" id="svg-ca44">
                    <path
                      d="m433.1 67.1-231.8 231.9c-6.2 6.2-16.4 6.2-22.6 0l-99.8-99.8-78.9 78.8 150.5 150.5c10.5 10.5 24.6 16.3 39.4 16.3 14.8 0 29-5.9 39.4-16.3l282.7-282.5z"
                      fill="#17477d"
                    ></path></svg
                ></span>
                <p>{{ $t("trainings.offer-26") }}</p>
              </div>
              <div>
                <span>
                  <svg class="icon" viewBox="0 0 512 512" id="svg-ca44">
                    <path
                      d="m433.1 67.1-231.8 231.9c-6.2 6.2-16.4 6.2-22.6 0l-99.8-99.8-78.9 78.8 150.5 150.5c10.5 10.5 24.6 16.3 39.4 16.3 14.8 0 29-5.9 39.4-16.3l282.7-282.5z"
                      fill="#17477d"
                    ></path></svg
                ></span>
                <p>{{ $t("trainings.offer-27") }}</p>
              </div>
              <div>
                <span>
                  <svg class="icon" viewBox="0 0 512 512" id="svg-ca44">
                    <path
                      d="m433.1 67.1-231.8 231.9c-6.2 6.2-16.4 6.2-22.6 0l-99.8-99.8-78.9 78.8 150.5 150.5c10.5 10.5 24.6 16.3 39.4 16.3 14.8 0 29-5.9 39.4-16.3l282.7-282.5z"
                      fill="#17477d"
                    ></path></svg
                ></span>
                <p>{{ $t("trainings.offer-28") }}</p>
              </div>
              <div>
                <span>
                  <svg class="icon" viewBox="0 0 512 512" id="svg-ca44">
                    <path
                      d="m433.1 67.1-231.8 231.9c-6.2 6.2-16.4 6.2-22.6 0l-99.8-99.8-78.9 78.8 150.5 150.5c10.5 10.5 24.6 16.3 39.4 16.3 14.8 0 29-5.9 39.4-16.3l282.7-282.5z"
                      fill="#17477d"
                    ></path></svg
                ></span>
                <p>{{ $t("trainings.offer-29") }}</p>
              </div>
              <div>
                <span>
                  <svg class="icon" viewBox="0 0 512 512" id="svg-ca44">
                    <path
                      d="m433.1 67.1-231.8 231.9c-6.2 6.2-16.4 6.2-22.6 0l-99.8-99.8-78.9 78.8 150.5 150.5c10.5 10.5 24.6 16.3 39.4 16.3 14.8 0 29-5.9 39.4-16.3l282.7-282.5z"
                      fill="#17477d"
                    ></path></svg
                ></span>
                <p>{{ $t("trainings.offer-30") }}</p>
              </div>
              <div>
                <span>
                  <svg class="icon" viewBox="0 0 512 512" id="svg-ca44">
                    <path
                      d="m433.1 67.1-231.8 231.9c-6.2 6.2-16.4 6.2-22.6 0l-99.8-99.8-78.9 78.8 150.5 150.5c10.5 10.5 24.6 16.3 39.4 16.3 14.8 0 29-5.9 39.4-16.3l282.7-282.5z"
                      fill="#17477d"
                    ></path></svg
                ></span>
                <p>{{ $t("trainings.offer-31") }}</p>
              </div>
              <div>
                <span>
                  <svg class="icon" viewBox="0 0 512 512" id="svg-ca44">
                    <path
                      d="m433.1 67.1-231.8 231.9c-6.2 6.2-16.4 6.2-22.6 0l-99.8-99.8-78.9 78.8 150.5 150.5c10.5 10.5 24.6 16.3 39.4 16.3 14.8 0 29-5.9 39.4-16.3l282.7-282.5z"
                      fill="#17477d"
                    ></path></svg
                ></span>
                <p>{{ $t("trainings.offer-32") }}</p>
              </div>
            </div>
          </section>

          <section>
            <h5>{{ $t("trainings.offer-title-03") }}</h5>
            <div class="offer">
              <div>
                <span>
                  <svg class="icon" viewBox="0 0 512 512" id="svg-ca44">
                    <path
                      d="m433.1 67.1-231.8 231.9c-6.2 6.2-16.4 6.2-22.6 0l-99.8-99.8-78.9 78.8 150.5 150.5c10.5 10.5 24.6 16.3 39.4 16.3 14.8 0 29-5.9 39.4-16.3l282.7-282.5z"
                      fill="#17477d"
                    ></path></svg
                ></span>
                <p>{{ $t("trainings.offer-33") }}</p>
              </div>
              <div>
                <span>
                  <svg class="icon" viewBox="0 0 512 512" id="svg-ca44">
                    <path
                      d="m433.1 67.1-231.8 231.9c-6.2 6.2-16.4 6.2-22.6 0l-99.8-99.8-78.9 78.8 150.5 150.5c10.5 10.5 24.6 16.3 39.4 16.3 14.8 0 29-5.9 39.4-16.3l282.7-282.5z"
                      fill="#17477d"
                    ></path></svg
                ></span>
                <p>{{ $t("trainings.offer-34") }}</p>
              </div>
              <div>
                <span>
                  <svg class="icon" viewBox="0 0 512 512" id="svg-ca44">
                    <path
                      d="m433.1 67.1-231.8 231.9c-6.2 6.2-16.4 6.2-22.6 0l-99.8-99.8-78.9 78.8 150.5 150.5c10.5 10.5 24.6 16.3 39.4 16.3 14.8 0 29-5.9 39.4-16.3l282.7-282.5z"
                      fill="#17477d"
                    ></path></svg
                ></span>
                <p>{{ $t("trainings.offer-35") }}</p>
              </div>
              <div>
                <span>
                  <svg class="icon" viewBox="0 0 512 512" id="svg-ca44">
                    <path
                      d="m433.1 67.1-231.8 231.9c-6.2 6.2-16.4 6.2-22.6 0l-99.8-99.8-78.9 78.8 150.5 150.5c10.5 10.5 24.6 16.3 39.4 16.3 14.8 0 29-5.9 39.4-16.3l282.7-282.5z"
                      fill="#17477d"
                    ></path></svg
                ></span>
                <p>{{ $t("trainings.offer-36") }}</p>
              </div>
              <div>
                <span>
                  <svg class="icon" viewBox="0 0 512 512" id="svg-ca44">
                    <path
                      d="m433.1 67.1-231.8 231.9c-6.2 6.2-16.4 6.2-22.6 0l-99.8-99.8-78.9 78.8 150.5 150.5c10.5 10.5 24.6 16.3 39.4 16.3 14.8 0 29-5.9 39.4-16.3l282.7-282.5z"
                      fill="#17477d"
                    ></path></svg
                ></span>
                <p>{{ $t("trainings.offer-37") }}</p>
              </div>
            </div>
          </section>
          <section>
            <h5>{{ $t("trainings.offer-title-07") }}</h5>
            <div class="offer">
              <div>
                <p>{{ $t("trainings.offer-39") }}</p>
              </div>
              <div>
                <span>
                  <svg class="icon" viewBox="0 0 512 512" id="svg-ca44">
                    <path
                      d="m433.1 67.1-231.8 231.9c-6.2 6.2-16.4 6.2-22.6 0l-99.8-99.8-78.9 78.8 150.5 150.5c10.5 10.5 24.6 16.3 39.4 16.3 14.8 0 29-5.9 39.4-16.3l282.7-282.5z"
                      fill="#17477d"
                    ></path></svg
                ></span>
                <p>{{ $t("trainings.offer-40") }}</p>
              </div>
              <div>
                <span>
                  <svg class="icon" viewBox="0 0 512 512" id="svg-ca44">
                    <path
                      d="m433.1 67.1-231.8 231.9c-6.2 6.2-16.4 6.2-22.6 0l-99.8-99.8-78.9 78.8 150.5 150.5c10.5 10.5 24.6 16.3 39.4 16.3 14.8 0 29-5.9 39.4-16.3l282.7-282.5z"
                      fill="#17477d"
                    ></path></svg
                ></span>
                <p>{{ $t("trainings.offer-40-supl") }}</p>
              </div>
              <div>
                <span>
                  <svg class="icon" viewBox="0 0 512 512" id="svg-ca44">
                    <path
                      d="m433.1 67.1-231.8 231.9c-6.2 6.2-16.4 6.2-22.6 0l-99.8-99.8-78.9 78.8 150.5 150.5c10.5 10.5 24.6 16.3 39.4 16.3 14.8 0 29-5.9 39.4-16.3l282.7-282.5z"
                      fill="#17477d"
                    ></path></svg
                ></span>
                <p>{{ $t("trainings.offer-41") }}</p>
              </div>
              <div>
                <span>
                  <svg class="icon" viewBox="0 0 512 512" id="svg-ca44">
                    <path
                      d="m433.1 67.1-231.8 231.9c-6.2 6.2-16.4 6.2-22.6 0l-99.8-99.8-78.9 78.8 150.5 150.5c10.5 10.5 24.6 16.3 39.4 16.3 14.8 0 29-5.9 39.4-16.3l282.7-282.5z"
                      fill="#17477d"
                    ></path></svg
                ></span>
                <p>{{ $t("trainings.offer-42") }}</p>
              </div>
              <div>
                <span>
                  <svg class="icon" viewBox="0 0 512 512" id="svg-ca44">
                    <path
                      d="m433.1 67.1-231.8 231.9c-6.2 6.2-16.4 6.2-22.6 0l-99.8-99.8-78.9 78.8 150.5 150.5c10.5 10.5 24.6 16.3 39.4 16.3 14.8 0 29-5.9 39.4-16.3l282.7-282.5z"
                      fill="#17477d"
                    ></path></svg
                ></span>
                <p>{{ $t("trainings.offer-43") }}</p>
              </div>
              <div>
                <span>
                  <svg class="icon" viewBox="0 0 512 512" id="svg-ca44">
                    <path
                      d="m433.1 67.1-231.8 231.9c-6.2 6.2-16.4 6.2-22.6 0l-99.8-99.8-78.9 78.8 150.5 150.5c10.5 10.5 24.6 16.3 39.4 16.3 14.8 0 29-5.9 39.4-16.3l282.7-282.5z"
                      fill="#17477d"
                    ></path></svg
                ></span>
                <p>{{ $t("trainings.offer-44") }}</p>
              </div>
              <div>
                <span>
                  <svg class="icon" viewBox="0 0 512 512" id="svg-ca44">
                    <path
                      d="m433.1 67.1-231.8 231.9c-6.2 6.2-16.4 6.2-22.6 0l-99.8-99.8-78.9 78.8 150.5 150.5c10.5 10.5 24.6 16.3 39.4 16.3 14.8 0 29-5.9 39.4-16.3l282.7-282.5z"
                      fill="#17477d"
                    ></path></svg
                ></span>
                <p>{{ $t("trainings.offer-45") }}</p>
              </div>
            </div>
          </section>
        </div>
        <div class="additional__info">
          <h5>{{ $t("trainings.offer-46") }}</h5>
          <p>{{ $t("trainings.offer-47") }}</p>
        </div>
        <div class="additional__info">
          <h5>{{ $t("trainings.offer-48") }}</h5>
          <p>{{ $t("trainings.offer-49") }}</p>
        </div>
        <div class="additional__info">
          <h5>{{ $t("trainings.offer-50") }}</h5>
          <p>{{ $t("trainings.offer-51") }}</p>
        </div>
      </div>

      <div class="tr__offers third">
        <h3>{{ $t("trainings.offer-title-08") }}</h3>
        <h5>{{ $t("trainings.offer-title-09") }}</h5>
        <p class="des">{{ $t("trainings.offer-53") }}</p>

        <div class="tr__offers_div">
          <section>
            <h5>{{ $t("trainings.offer-54") }}</h5>
            <div class="offer">
              <div>
                <p>{{ $t("trainings.offer-55") }}</p>
              </div>
              <div>
                <span>
                  <svg class="icon" viewBox="0 0 512 512" id="svg-ca44">
                    <path
                      d="m433.1 67.1-231.8 231.9c-6.2 6.2-16.4 6.2-22.6 0l-99.8-99.8-78.9 78.8 150.5 150.5c10.5 10.5 24.6 16.3 39.4 16.3 14.8 0 29-5.9 39.4-16.3l282.7-282.5z"
                      fill="#17477d"
                    ></path></svg
                ></span>
                <p>{{ $t("trainings.offer-56") }}</p>
              </div>
              <div>
                <span>
                  <svg class="icon" viewBox="0 0 512 512" id="svg-ca44">
                    <path
                      d="m433.1 67.1-231.8 231.9c-6.2 6.2-16.4 6.2-22.6 0l-99.8-99.8-78.9 78.8 150.5 150.5c10.5 10.5 24.6 16.3 39.4 16.3 14.8 0 29-5.9 39.4-16.3l282.7-282.5z"
                      fill="#17477d"
                    ></path></svg
                ></span>
                <p>{{ $t("trainings.offer-57") }}</p>
              </div>
              <div>
                <span>
                  <svg class="icon" viewBox="0 0 512 512" id="svg-ca44">
                    <path
                      d="m433.1 67.1-231.8 231.9c-6.2 6.2-16.4 6.2-22.6 0l-99.8-99.8-78.9 78.8 150.5 150.5c10.5 10.5 24.6 16.3 39.4 16.3 14.8 0 29-5.9 39.4-16.3l282.7-282.5z"
                      fill="#17477d"
                    ></path></svg
                ></span>
                <p>{{ $t("trainings.offer-58") }}</p>
              </div>
            </div>
          </section>
          <section>
            <h5>{{ $t("trainings.offer-61") }}</h5>
            <div class="offer">
              <div>
                <span>
                  <svg class="icon" viewBox="0 0 512 512" id="svg-ca44">
                    <path
                      d="m433.1 67.1-231.8 231.9c-6.2 6.2-16.4 6.2-22.6 0l-99.8-99.8-78.9 78.8 150.5 150.5c10.5 10.5 24.6 16.3 39.4 16.3 14.8 0 29-5.9 39.4-16.3l282.7-282.5z"
                      fill="#17477d"
                    ></path></svg
                ></span>
                <p>{{ $t("trainings.offer-62") }}</p>
              </div>
              <div>
                <span>
                  <svg class="icon" viewBox="0 0 512 512" id="svg-ca44">
                    <path
                      d="m433.1 67.1-231.8 231.9c-6.2 6.2-16.4 6.2-22.6 0l-99.8-99.8-78.9 78.8 150.5 150.5c10.5 10.5 24.6 16.3 39.4 16.3 14.8 0 29-5.9 39.4-16.3l282.7-282.5z"
                      fill="#17477d"
                    ></path></svg
                ></span>
                <p>{{ $t("trainings.offer-63") }}</p>
              </div>
              <div>
                <span>
                  <svg class="icon" viewBox="0 0 512 512" id="svg-ca44">
                    <path
                      d="m433.1 67.1-231.8 231.9c-6.2 6.2-16.4 6.2-22.6 0l-99.8-99.8-78.9 78.8 150.5 150.5c10.5 10.5 24.6 16.3 39.4 16.3 14.8 0 29-5.9 39.4-16.3l282.7-282.5z"
                      fill="#17477d"
                    ></path></svg
                ></span>
                <p>{{ $t("trainings.offer-64") }}</p>
              </div>
              <div>
                <span>
                  <svg class="icon" viewBox="0 0 512 512" id="svg-ca44">
                    <path
                      d="m433.1 67.1-231.8 231.9c-6.2 6.2-16.4 6.2-22.6 0l-99.8-99.8-78.9 78.8 150.5 150.5c10.5 10.5 24.6 16.3 39.4 16.3 14.8 0 29-5.9 39.4-16.3l282.7-282.5z"
                      fill="#17477d"
                    ></path></svg
                ></span>
                <p>{{ $t("trainings.offer-65") }}</p>
              </div>
              <div>
                <span>
                  <svg class="icon" viewBox="0 0 512 512" id="svg-ca44">
                    <path
                      d="m433.1 67.1-231.8 231.9c-6.2 6.2-16.4 6.2-22.6 0l-99.8-99.8-78.9 78.8 150.5 150.5c10.5 10.5 24.6 16.3 39.4 16.3 14.8 0 29-5.9 39.4-16.3l282.7-282.5z"
                      fill="#17477d"
                    ></path></svg
                ></span>
                <p>{{ $t("trainings.offer-66") }}</p>
              </div>
              <div>
                <span>
                  <svg class="icon" viewBox="0 0 512 512" id="svg-ca44">
                    <path
                      d="m433.1 67.1-231.8 231.9c-6.2 6.2-16.4 6.2-22.6 0l-99.8-99.8-78.9 78.8 150.5 150.5c10.5 10.5 24.6 16.3 39.4 16.3 14.8 0 29-5.9 39.4-16.3l282.7-282.5z"
                      fill="#17477d"
                    ></path></svg
                ></span>
                <p>{{ $t("trainings.offer-67") }}</p>
              </div>
            </div>
          </section>

          <section>
            <h5>{{ $t("trainings.offer-59") }}</h5>
            <div class="offer">
              <div>
                <span>
                  <svg class="icon" viewBox="0 0 512 512" id="svg-ca44">
                    <path
                      d="m433.1 67.1-231.8 231.9c-6.2 6.2-16.4 6.2-22.6 0l-99.8-99.8-78.9 78.8 150.5 150.5c10.5 10.5 24.6 16.3 39.4 16.3 14.8 0 29-5.9 39.4-16.3l282.7-282.5z"
                      fill="#17477d"
                    ></path></svg
                ></span>
                <p>{{ $t("trainings.offer-68") }}</p>
              </div>
            </div>
          </section>
        </div>
      </div>

      <div class="tr__offers fourth">
        <h3>{{ $t("trainings.offer-title-10") }}</h3>
        <h5>{{ $t("trainings.offer-title-11") }}</h5>
        <p class="des">{{ $t("trainings.offer-68") }}</p>

        <div class="tr__offers_div">
          <section>
            <h5>{{ $t("trainings.offer-69") }}</h5>
            <div class="offer">
              <div>
                <span>
                  <svg class="icon" viewBox="0 0 512 512" id="svg-ca44">
                    <path
                      d="m433.1 67.1-231.8 231.9c-6.2 6.2-16.4 6.2-22.6 0l-99.8-99.8-78.9 78.8 150.5 150.5c10.5 10.5 24.6 16.3 39.4 16.3 14.8 0 29-5.9 39.4-16.3l282.7-282.5z"
                      fill="#17477d"
                    ></path></svg
                ></span>
                <p>{{ $t("trainings.offer-70") }}</p>
              </div>
            </div>
          </section>
          <section>
            <h5>{{ $t("trainings.offer-71") }}</h5>
            <div class="offer">
              <div>
                <span>
                  <svg class="icon" viewBox="0 0 512 512" id="svg-ca44">
                    <path
                      d="m433.1 67.1-231.8 231.9c-6.2 6.2-16.4 6.2-22.6 0l-99.8-99.8-78.9 78.8 150.5 150.5c10.5 10.5 24.6 16.3 39.4 16.3 14.8 0 29-5.9 39.4-16.3l282.7-282.5z"
                      fill="#17477d"
                    ></path></svg
                ></span>
                <p>{{ $t("trainings.offer-72") }}</p>
              </div>
            </div>
          </section>
          <section>
            <h5>{{ $t("trainings.offer-73") }}</h5>
            <div class="offer">
              <div>
                <span>
                  <svg class="icon" viewBox="0 0 512 512" id="svg-ca44">
                    <path
                      d="m433.1 67.1-231.8 231.9c-6.2 6.2-16.4 6.2-22.6 0l-99.8-99.8-78.9 78.8 150.5 150.5c10.5 10.5 24.6 16.3 39.4 16.3 14.8 0 29-5.9 39.4-16.3l282.7-282.5z"
                      fill="#17477d"
                    ></path></svg
                ></span>
                <p>{{ $t("trainings.offer-74") }}</p>
              </div>
              <div>
                <span>
                  <svg class="icon" viewBox="0 0 512 512" id="svg-ca44">
                    <path
                      d="m433.1 67.1-231.8 231.9c-6.2 6.2-16.4 6.2-22.6 0l-99.8-99.8-78.9 78.8 150.5 150.5c10.5 10.5 24.6 16.3 39.4 16.3 14.8 0 29-5.9 39.4-16.3l282.7-282.5z"
                      fill="#17477d"
                    ></path></svg
                ></span>
                <p>{{ $t("trainings.offer-75") }}</p>
              </div>
              <div>
                <span>
                  <svg class="icon" viewBox="0 0 512 512" id="svg-ca44">
                    <path
                      d="m433.1 67.1-231.8 231.9c-6.2 6.2-16.4 6.2-22.6 0l-99.8-99.8-78.9 78.8 150.5 150.5c10.5 10.5 24.6 16.3 39.4 16.3 14.8 0 29-5.9 39.4-16.3l282.7-282.5z"
                      fill="#17477d"
                    ></path></svg
                ></span>
                <p>{{ $t("trainings.offer-76") }}</p>
              </div>
            </div>
          </section>
          <section>
            <h5>{{ $t("trainings.offer-77") }}</h5>
            <div class="offer">
              <div>
                <span>
                  <svg class="icon" viewBox="0 0 512 512" id="svg-ca44">
                    <path
                      d="m433.1 67.1-231.8 231.9c-6.2 6.2-16.4 6.2-22.6 0l-99.8-99.8-78.9 78.8 150.5 150.5c10.5 10.5 24.6 16.3 39.4 16.3 14.8 0 29-5.9 39.4-16.3l282.7-282.5z"
                      fill="#17477d"
                    ></path></svg
                ></span>
                <p>{{ $t("trainings.offer-78") }}</p>
              </div>
              <div>
                <span>
                  <svg class="icon" viewBox="0 0 512 512" id="svg-ca44">
                    <path
                      d="m433.1 67.1-231.8 231.9c-6.2 6.2-16.4 6.2-22.6 0l-99.8-99.8-78.9 78.8 150.5 150.5c10.5 10.5 24.6 16.3 39.4 16.3 14.8 0 29-5.9 39.4-16.3l282.7-282.5z"
                      fill="#17477d"
                    ></path></svg
                ></span>
                <p>{{ $t("trainings.offer-79") }}</p>
              </div>
              <div>
                <span>
                  <svg class="icon" viewBox="0 0 512 512" id="svg-ca44">
                    <path
                      d="m433.1 67.1-231.8 231.9c-6.2 6.2-16.4 6.2-22.6 0l-99.8-99.8-78.9 78.8 150.5 150.5c10.5 10.5 24.6 16.3 39.4 16.3 14.8 0 29-5.9 39.4-16.3l282.7-282.5z"
                      fill="#17477d"
                    ></path></svg
                ></span>
                <p>{{ $t("trainings.offer-80") }}</p>
              </div>
              <div>
                <span>
                  <svg class="icon" viewBox="0 0 512 512" id="svg-ca44">
                    <path
                      d="m433.1 67.1-231.8 231.9c-6.2 6.2-16.4 6.2-22.6 0l-99.8-99.8-78.9 78.8 150.5 150.5c10.5 10.5 24.6 16.3 39.4 16.3 14.8 0 29-5.9 39.4-16.3l282.7-282.5z"
                      fill="#17477d"
                    ></path></svg
                ></span>
                <p>{{ $t("trainings.offer-81") }}</p>
              </div>
              <div>
                <span>
                  <svg class="icon" viewBox="0 0 512 512" id="svg-ca44">
                    <path
                      d="m433.1 67.1-231.8 231.9c-6.2 6.2-16.4 6.2-22.6 0l-99.8-99.8-78.9 78.8 150.5 150.5c10.5 10.5 24.6 16.3 39.4 16.3 14.8 0 29-5.9 39.4-16.3l282.7-282.5z"
                      fill="#17477d"
                    ></path></svg
                ></span>
                <p>{{ $t("trainings.offer-82") }}</p>
              </div>
            </div>
          </section>

          <section>
            <h5>{{ $t("trainings.offer-83") }}</h5>
            <div class="offer">
              <div>
                <span>
                  <svg class="icon" viewBox="0 0 512 512" id="svg-ca44">
                    <path
                      d="m433.1 67.1-231.8 231.9c-6.2 6.2-16.4 6.2-22.6 0l-99.8-99.8-78.9 78.8 150.5 150.5c10.5 10.5 24.6 16.3 39.4 16.3 14.8 0 29-5.9 39.4-16.3l282.7-282.5z"
                      fill="#17477d"
                    ></path></svg
                ></span>
                <p>{{ $t("trainings.offer-84") }}</p>
              </div>
            </div>
          </section>
        </div>
      </div>
      </vue-if-bot>
    </div>
</template>

<script>
export default {
  name: "Trainings",
  components: {
    VueIfBot: () => import("vue-if-bot"),
  },
  data() {
    return {}
  },
};
</script>

<style lang="scss">
.tr__container {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;

  .tr__img {
    background-image: url("../assets/img/trainings.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-position: top;
    height: 550px;
    position: relative;
    .tr__inside__el {
      border: 2px solid;
      border-color: #ffff;
      background-color: rgba(23, 71, 125, 0.61);
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      img {
        height: 200px;
        position: absolute;
        top: 0%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
      .text {
        margin: 70px 30px;
        color: white;
        font-size: 16px;
        text-align: justify;
        opacity: 1;
        font-family: "Ubuntu", sans-serif;
        font-weight: 600;
        line-height: 1.5;
      }
      @media (max-width: 1140px) {
        .text {
          width: 300px;
          font-size: 12px;
          margin: 60px 20px 20px 20px;
        }
      }
    }
  }

  @media (max-width: 1140px) {
    .tr__img {
      background-size: cover;
    }
  }

  .tr__text {
    background-color: #17477d;
    border-top: 2px solid #ffff;

    p {
      padding: 70px 194px 70px 136px;
      font-size: 16px;
      color: #ffff;
      font-weight: 600;
      text-align: center;
    }
  }

  @media (max-width: 1140px) {
    .tr__text {
      p {
        padding: 20px;
        font-size: 14px;
      }
    }
  }

  .tr__offers {
    h3,
    h5 {
      text-align: center;
    }
    h3 {
      color: #17477d;
      padding: 30px 0 10px 0;
      font-size: 28px;
    }
    h5 {
      font-size: 16px;
    }

    .tr__offers_div {
      display: flex;
      padding: 30px 194px 30px 136px;
      gap: 50px;

      section {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        h5 {
          align-self: flex-start;
          padding-bottom: 20px;
        }
        .offer {
          display: flex;
          flex-direction: column;
          div {
            display: flex;
            span {
              margin-right: 8px;
              align-items: center;
              .icon {
                width: 15px;
                height: 15px;
              }
            }
          }
        }
      }
    }

    @media (max-width: 1140px) {
      .tr__offers_div {
        flex-direction: column;
        padding: 20px;
      }
    }

    &.second {
      background-color: #e4e4f1;
      section {
        flex-basis: 33%;
      }

      .additional__info {
        padding: 5px 194px 20px 136px;
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
        h5 {
          text-align: start;
          padding-bottom: 10px;
        }

        p {
          font-size: 14px;
        }
      }

      @media (max-width: 1140px) {
        .additional__info {
          padding: 20px;
        }
      }

      .des {
        width: 80%;
        font-size: 14px;
        padding: 20px 194px 10px 136px;
        margin: 0 auto;
        text-align: center;
      }

      @media (max-width: 1140px) {
        .des {
          width: 100%;
          padding: 20px;
        }
      }
    }

    &.third {
      section {
        flex-basis: 35%;

        h5 {
          text-align: start;
        }
      }
      .des {
        width: 80%;
        font-size: 14px;
        padding: 20px 194px 10px 136px;
        margin: 0 auto;
        text-align: center;
      }

      @media (max-width: 1140px) {
        .des {
          width: 100%;
          padding: 20px;
        }
      }
    }

    &.fourth {
      background-color: #e4e4f1;

      section {
        flex-basis: 35%;

        h5 {
          text-align: start;
        }
      }
      .des {
        width: 80%;
        font-size: 14px;
        padding: 20px 194px 10px 136px;
        margin: 0 auto;
        text-align: center;
      }

      @media (max-width: 1140px) {
        .des {
          width: 100%;
          padding: 20px;
        }
      }
    }

    p {
      font-size: 14px;
      margin-bottom: 10px;
    }
  }
}
</style>