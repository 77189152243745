<template>
  <vue-if-bot>
    <div class="aboutus__container">
      <div data-aos="flip-up" class="aboutus__img">
        <div class="aboutus__inside__el">
          <img src="@/assets/img/logo.png" alt="logo" />
          <p data-aos="zoom-in-down" class="text">
            {{ $t("aboutus.description") }}
          </p>
        </div>
      </div>

      <div class="aboutus__info">
        <div data-aos="zoom-in-down" class="aboutus__info_el">
          <div class="aboutus__info_first">
            <p>{{ $t("aboutus.annonce") }}</p>
          </div>
          <div class="aboutus__info_sec">
            <p>{{ $t("aboutus.text-01") }}</p>
            <ul>
              <li>{{ $t("aboutus.text-02") }}</li>
              <li>{{ $t("aboutus.text-03") }}</li>
              <li>{{ $t("aboutus.text-04") }}</li>
              <li>{{ $t("aboutus.text-05") }}</li>
              <li>{{ $t("aboutus.text-06") }}</li>
            </ul>
          </div>
        </div>
        <div data-aos="zoom-in-down" class="aboutus__info_img">
          <section class="img__remove_padding">
            <img src="@/assets/img/bvd-logo.png" alt="" />
          </section>
            <section class="img__add_padding">
            <img src="@/assets/img/nbu-logo.png" alt="" />
          </section>
          <section class="img__add_padding">
            <img src="@/assets/img/allianz-logo.png" alt="" />
          </section>
        </div>
      </div>

      <div class="aboutus__team">
        <div class="aboutus__team_heading">
          <h2>{{ $t("aboutus.title") }}</h2>
          <p class="desc_team">{{ $t("aboutus.desc") }}</p>
          <p class="desc_team">{{ $t("aboutus.desc-01") }}</p>
        </div>

        <div class="aboutus__team_person" >
          <section data-aos="zoom-in-down" class="section_bytree">
            <section class="person">
              <div class="circle">
                <img src="@/assets/img/person.jpg" alt="" />
              </div>
              <div class="bio">
                <p class="name" @click="open_1 = !open_1">
                  {{ $t("aboutus.name") }}
                  <span class="icon">
                    <img src="@/assets/icon/arrow_drop_down.svg" alt="icon"
                  /></span>
                </p>
                <p v-if="open_1" class="name__dyn">
                  {{ $t("aboutus.stannie-bio") }}
                </p>
                <p class="name" @click="open_2 = !open_2">
                  {{ $t("aboutus.specialist") }}
                  <span class="icon">
                    <img src="@/assets/icon/arrow_drop_down.svg" alt="icon"
                  /></span>
                </p>
                <p v-if="open_2" class="name__dyn">
                  {{ $t("aboutus.spec-desc") }}
                  <ul>
                    <li>{{ $t("aboutus.spec-desc-stanni-1") }}</li>
                    <li>{{ $t("aboutus.spec-desc-stanni-2") }}</li>
                    <li>{{ $t("aboutus.spec-desc-stanni-3") }}</li>
                </ul>
                </p>

              </div>
            </section>
            <section class="person">
              <div class="circle">
                <img src="@/assets/img/person-09.jpg" alt="icon" />
              </div>
              <div class="bio">
                <p class="name" @click="open_3 = !open_3">
                  {{ $t("aboutus.name-00") }}
                  <span class="icon">
                    <img src="@/assets/icon/arrow_drop_down.svg" alt="icon"
                  /></span>
                </p>
                <p v-if="open_3" class="name__dyn">
                  {{ $t("aboutus.dilyan-bio") }}
                </p>
                <p class="name" @click="open_4 = !open_4">
                  {{ $t("aboutus.specialist") }}
                  <span class="icon">
                    <img src="@/assets/icon/arrow_drop_down.svg" alt="icon"
                  /></span>
                </p>
                <p v-if="open_4" class="name__dyn">
                  {{ $t("aboutus.spec-desc-01") }}
                </p>
              </div>
            </section>
            <section class="person">
              <div class="circle">
                <img src="@/assets/img/person-04.jpg" alt="" />
              </div>
              <div class="bio">
                <p class="name" @click="open_5 = !open_5">
                  {{ $t("aboutus.name-01") }}
                  <span class="icon">
                    <img src="@/assets/icon/arrow_drop_down.svg" alt="icon"
                  /></span>
                </p>
                <p v-if="open_5" class="name__dyn">
                  {{ $t("aboutus.yoana-bio") }}
                </p>
                <p class="name" @click="open_6 = !open_6">
                  {{ $t("aboutus.specialist") }}
                  <span class="icon">
                    <img src="@/assets/icon/arrow_drop_down.svg" alt="icon"
                  /></span>
                </p>
                <p v-if="open_6" class="name__dyn">
                  {{ $t("aboutus.spec-desc-02") }}
                </p>
              </div>
            </section>
          </section>
          <section data-aos="zoom-in-down" class="section_bytree">
            <section class="person">
              <div class="circle">
                <img src="@/assets/img/person-06.jpg" alt="" />
              </div>
              <div class="bio">
                <p class="name" @click="open_7 = !open_7">
                  {{ $t("aboutus.name-02") }}
                  <span class="icon">
                    <img src="@/assets/icon/arrow_drop_down.svg" alt="icon"
                  /></span>
                </p>
                <p v-if="open_7" class="name__dyn">
                  {{ $t("aboutus.pirinka-bio") }}
                </p>
                <p class="name" @click="open_8 = !open_8">
                  {{ $t("aboutus.specialist") }}
                  <span class="icon">
                    <img src="@/assets/icon/arrow_drop_down.svg" alt="icon"
                  /></span>
                </p>
                <p v-if="open_8" class="name__dyn">
                  {{ $t("aboutus.spec-desc-03") }}
                </p>
              </div>
            </section>
            <section class="person">
              <div class="circle">
                <img src="@/assets/img//person-07.jpg" alt="" />
              </div>
              <div class="bio">
                <p class="name" @click="open_9 = !open_9">
                  {{ $t("aboutus.name-03") }}
                  <span class="icon">
                    <img src="@/assets/icon/arrow_drop_down.svg" alt="icon"
                  /></span>
                </p>
                <p v-if="open_9" class="name__dyn">
                  {{ $t("aboutus.miglena-bio") }}
                </p>
                <p class="name" @click="open_10 = !open_10">
                  {{ $t("aboutus.specialist") }}
                  <span class="icon">
                    <img src="@/assets/icon/arrow_drop_down.svg" alt="icon"
                  /></span>
                </p>
                <p v-if="open_10" class="name__dyn">
                  {{ $t("aboutus.spec-desc-04") }}
                </p>
              </div>
            </section>

          </section>
          <section data-aos="zoom-in-down" class="section_bytree">
            <section class="person">
              <div class="circle">
                <img src="@/assets/img/person-05.jpg" alt="" />
              </div>
              <div class="bio">
                <p class="name" @click="open_13 = !open_13">
                  {{ $t("aboutus.name-05") }}
                  <span class="icon">
                    <img src="@/assets/icon/arrow_drop_down.svg" alt="icon"
                  /></span>
                </p>
                <p v-if="open_13" class="name__dyn">
                  {{ $t("aboutus.veselka-bio") }}
                </p>
                <p class="name" @click="open_14 = !open_14">
                  {{ $t("aboutus.specialist") }}
                  <span class="icon">
                    <img src="@/assets/icon/arrow_drop_down.svg" alt="icon"
                  /></span>
                </p>
                <p v-if="open_14" class="name__dyn">
                  {{ $t("aboutus.spec-desc-06") }}
                </p>
              </div>
            </section>
            <section class="person">
              <div class="circle">
                <img src="@/assets/img/person-01.jpg" alt="" />
              </div>
              <div class="bio">
                <p class="name" @click="open_15 = !open_15">
                  {{ $t("aboutus.name-06") }}
                  <span class="icon">
                    <img src="@/assets/icon/arrow_drop_down.svg" alt="icon"
                  /></span>
                </p>
                <p v-if="open_15" class="name__dyn">
                  {{ $t("aboutus.kristian-bio") }}
                </p>
                <p class="name" @click="open_16 = !open_16">
                  {{ $t("aboutus.specialist") }}
                  <span class="icon">
                    <img src="@/assets/icon/arrow_drop_down.svg" alt="icon"
                  /></span>
                </p>
                <p v-if="open_16" class="name__dyn">
                  {{ $t("aboutus.spec-desc-00") }}
                </p>
              </div>
            </section>
            <section class="person">
              <div class="circle">
                <img src="@/assets/img/person-02.jpg" alt="" />
              </div>
              <div class="bio">
                <p class="name" @click="open_17 = !open_17">
                  {{ $t("aboutus.name-07") }}
                  <span class="icon">
                    <img src="@/assets/icon/arrow_drop_down.svg" alt="icon"
                  /></span>
                </p>
                <p v-if="open_17" class="name__dyn">
                  {{ $t("aboutus.lyudmila-bio") }}
                </p>
                <p class="name" @click="open_18 = !open_18">
                  {{ $t("aboutus.specialist") }}
                  <span class="icon">
                    <img src="@/assets/icon/arrow_drop_down.svg" alt="icon"
                  /></span>
                </p>
                <p v-if="open_18" class="name__dyn">
                  {{ $t("aboutus.spec-desc-07") }}
                </p>
              </div>
            </section>
          </section>
        </div>
      </div>
      <div data-aos="zoom-in-down" class="aboutus__publications">
        <div>
          <p>{{ $t("aboutus.text00") }}</p>
          <h4>{{ $t("aboutus.text01") }}</h4>
          <ul>
            <li>{{ $t("aboutus.text02") }}</li>
            <li>{{ $t("aboutus.text03") }}</li>
            <li>{{ $t("aboutus.text04") }}</li>
            <li>{{ $t("aboutus.text05") }}</li>
            <li>{{ $t("aboutus.text06") }}</li>
            <li>{{ $t("aboutus.text07") }}</li>
            <li>{{ $t("aboutus.text08") }}</li>
            <li>{{ $t("aboutus.text09") }}</li>
            <li>{{ $t("aboutus.text10") }}</li>
            <li>{{ $t("aboutus.text11") }}</li>
            <li>{{ $t("aboutus.text12") }}</li>
            <li>{{ $t("aboutus.text13") }}</li>
            <li>{{ $t("aboutus.text14") }}</li>
          </ul>
        </div>
      </div>
    </div>
  </vue-if-bot>
</template>


<script>
export default {
  name: "About",
  components: {
    VueIfBot: () => import('vue-if-bot')
  },
  data() {
    return {
      open_1: false,
      open_2: false,
      open_3: false,
      open_4: false,
      open_5: false,
      open_6: false,
      open_7: false,
      open_8: false,
      open_9: false,
      open_10: false,
      open_11: false,
      open_12: false,
      open_13: false,
      open_14: false,
      open_15: false,
      open_16: false,
      open_17: false,
      open_18: false,
      open_19: false,
      open_20: false,
      open_21: false,
      open_22: false,
      open_23: false,
      elements: [],
    };
  },
  methods: {},
};
</script>

<style lang="scss">
.aboutus__container {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
  .aboutus__img {
    background-image: url("../assets/img/about-us-04.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-position: top;
    height: 550px;
    position: relative;

    .aboutus__inside__el {
      border: 2px solid;
      border-color: #ffff;
      background-color: rgba(23, 71, 125, 0.61);
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      img {
        height: 200px;
        position: absolute;
        top: 0%;
        left: 50%;
        transform: translate(-50%, -50%);
      }

      .text {
        margin: 70px 30px;
        color: white;
        font-size: 16px;
        text-align: justify;
        opacity: 1;
        font-family: "Ubuntu", sans-serif;
        font-weight: 600;
        line-height: 1.5;
      }

      @media (max-width: 1140px) {
        .text {
          width: 300px;
          font-size: 12px;
          margin: 60px 20px 20px 20px;
        }
      }
    }
  }

  .aboutus__info {
    background-color: #17477d;
    border-top: 2px solid #ffff;
    padding: 50px 194px 50px 136px;
    .aboutus__info_el {
      margin: 0 auto;
      margin-bottom: 50px;
      display: flex;

      .aboutus__info_first {
        flex-basis: 30%;
        background-color: #ffff;
        display: flex;
        justify-content: center;
        align-items: center;

        p {
          width: 70%;
          font-size: 20px;
          color: #17477d;
          text-align: center;
          font-style: italic;
        }
      }
      .aboutus__info_sec {
        flex-basis: 70%;
        background-color: #9b9b9b;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;
        padding: 30px;
        p {
          font-size: 14px;
          color: #ffff;
          line-height: 1.4;
          font-weight: 600;
          text-align: justify;
        }

        li {
          font-size: 14px;
          color: #ffff;
          line-height: 1.4;

          text-align: justify;
        }

        p:first-of-type {
          padding-bottom: 10px;
          font-weight: 500;
        }
      }
    }

    @media (max-width: 1140px) {
      .aboutus__info_el {
        flex-direction: column;
      }
    }

    .aboutus__info_img {
      height: auto;
      margin: 0 auto;
      display: flex;
      justify-content: center;
      gap: 50px;
      background-color: #ffff;

      section {
        width: 280px;
        img {
          width: 220px;
          height: auto; 
        }
      }

      .img__add_padding {
        padding: 20px;
      }
    }
    
    @media (max-width: 1140px) {
      .aboutus__info_img {
        height: auto;
        gap: 20px;

        section {
          height: auto;
          width: 200px;
          img {
            width: 100%;
            height: auto;
          }
        }
      }
    }
  }

  @media (max-width: 1140px) {
    .aboutus__info {
      padding: 20px;
    }
  }

  .aboutus__team {
    padding: 30px 194px 50px 136px;
    h2 {
      color: #17477d;
      text-align: center;
      padding-bottom: 20px;
    }
    .desc_team {
      text-align: center;
      font-size: 20px;
      color: #9b9b9b;
      width: 60%;
      margin: 0 auto;
    }

    @media (max-width: 1140px) {
      .desc_team {
        width: 90%;
      }
    }
    .desc_team:first-of-type {
      padding-bottom: 10px;
      font-weight: 600;
    }

    .aboutus__team_person {
      margin-top: 20px;
      padding: 10px 0;
      display: flex;
      flex-direction: column;
      gap: 10px;
      .section_bytree {
        display: flex;
        justify-content: space-between;
        gap: 10px;
        .person {
          flex-basis: 32%;
          flex-shrink: 0;
          flex-grow: 1;
          background-color: #f3f0f0;
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 20px;
          padding: 20px 0;
          p {
            color: #17477d;
          }
          .circle {
            display: flex;
            justify-content: center;
            align-items: center;
            img {
              height: 200px;
              width: 200px;
              border-radius: 50%;
              align-items: center;
            }
          }

          .bio {
            display: flex;
            flex-direction: column;
            width: 90%;
            gap: 10px;

            .name {
              padding: 10px;
              border: 1px solid #17477d;
              display: flex;
              justify-content: space-between;
              align-items: center;
              font-size: 16px;

              .icon {
                cursor: pointer;
                img {
                  width: 30px;
                  height: 30px;
                }
              }
            }
            .name__dyn {
              font-size: 14px;
              padding: 35px;
              background-color: #17477d;
              color: #ffff;
            }
          }
        }
      }

      @media (max-width: 1140px) {
        .section_bytree {
          display: initial;
        }
      }
    }
  }

  @media (max-width: 1140px) {
    .aboutus__team {
      padding: 20px;
    }
  }

  .aboutus__publications {
    background-color: #17477d;
    div {
      color: #ffff;
      font-size: 16px;
      padding: 30px 194px 30px 136px;
      p {
        padding: 4px;
      }
      h4 {
        font-size: 18px;
        padding: 10px;
      }
      li {
        padding: 4px;
      }
    }
  }

  @media (max-width: 1140px) {
    .aboutus__publications {
      div {
        padding: 20px;
        p {
          padding: 4px;
        }
        h4 {
          font-size: 18px;
          padding: 10px;
        }
        li {
          padding: 4px;
        }
      }
    }
  }
}
</style>