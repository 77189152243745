<template>
  <vue-if-bot>
    <div class="blog__container">
      <h3 @click="readFile()">1. Newsletter - November 2023</h3>
    </div>
  </vue-if-bot>
</template>

<script>
export default {
  name: "Blog",
  components: {
    VueIfBot: () => import("vue-if-bot"),
  },
  data() {
    return {
    };
  },
  methods: {
  readFile() {
     window.open('newsletter.pdf')
   }
}
};
</script>

<style lang="scss">
.blog__container {
  background-color: rgb(224,224,224);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
  height: 100vh;
  display: flex;
  justify-self: center;
  flex-direction: column;

  h3 {
      padding: 50px 194px 30px 136px;
      font-size: 26px;
      cursor: pointer;
      color: #17477d;
  }
   
}
</style>