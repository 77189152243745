import { render, staticRenderFns } from "./Trainings.vue?vue&type=template&id=188fd9aa&"
import script from "./Trainings.vue?vue&type=script&lang=js&"
export * from "./Trainings.vue?vue&type=script&lang=js&"
import style0 from "./Trainings.vue?vue&type=style&index=0&id=188fd9aa&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports