<template>
  <vue-if-bot>
    <div class="job__container">
      <div data-aos="flip-up" class="job__img">
        <div class="job__inside__el">
          <img src="@/assets/img/logo.png" alt="logo" />
          <h2 class="title">
            {{ $t("job.title") }}
          </h2>
        </div>
      </div>

      <div class="job__info">
        <div class="job__info_positions">
          <div>
            <svg class="icon" viewBox="0 0 512 512" id="svg-ca44">
              <path
                d="m433.1 67.1-231.8 231.9c-6.2 6.2-16.4 6.2-22.6 0l-99.8-99.8-78.9 78.8 150.5 150.5c10.5 10.5 24.6 16.3 39.4 16.3 14.8 0 29-5.9 39.4-16.3l282.7-282.5z"
                fill="currentColor"
              ></path>
            </svg>
            <p>{{ $t("job.open-position-01") }}</p>
          </div>
          <div>
            <svg class="icon" viewBox="0 0 512 512" id="svg-ca44">
              <path
                d="m433.1 67.1-231.8 231.9c-6.2 6.2-16.4 6.2-22.6 0l-99.8-99.8-78.9 78.8 150.5 150.5c10.5 10.5 24.6 16.3 39.4 16.3 14.8 0 29-5.9 39.4-16.3l282.7-282.5z"
                fill="currentColor"
              ></path>
            </svg>
            <p>{{ $t("job.open-position-02") }}</p>
          </div>

          <div>
            <svg class="icon" viewBox="0 0 512 512" id="svg-ca44">
              <path
                d="m433.1 67.1-231.8 231.9c-6.2 6.2-16.4 6.2-22.6 0l-99.8-99.8-78.9 78.8 150.5 150.5c10.5 10.5 24.6 16.3 39.4 16.3 14.8 0 29-5.9 39.4-16.3l282.7-282.5z"
                fill="currentColor"
              ></path>
            </svg>
            <p>{{ $t("job.open-position-03") }}</p>
          </div>
        </div>
      </div>
      <div class="job__adverts">
        <div class="ad_f">
          <h2>{{ $t("job.open-position-01") }}</h2>
          <p>{{ $t("job.desc") }}</p>
          <h3>{{ $t("job.desc-01") }}</h3>
          <p>{{ $t("job.desc-02") }}</p>
          <div class="inside">
            <p>{{ $t("job.desc-03") }}</p>
            <p>{{ $t("job.desc-04") }}</p>
            <p>{{ $t("job.desc-05") }}</p>
            <p>{{ $t("job.desc-06") }}</p>
            <p>{{ $t("job.desc-07") }}</p>
            <p>{{ $t("job.desc-08") }}</p>
            <p>{{ $t("job.desc-09") }}</p>
          </div>
          <p>{{ $t("job.desc-10") }}</p>
          <div class="inside">
            <p>{{ $t("job.desc-11") }}</p>
            <p>{{ $t("job.desc-12") }}</p>
            <p>{{ $t("job.desc-13") }}</p>
          </div>

          <p>{{ $t("job.desc-14") }}</p>
        </div>
        <br />
        <hr />
        <br />
        <div class="ad_s">
          <h2>{{ $t("job.open-position-02") }}</h2>
          <p>{{ $t("job.desc-15") }}</p>
          <p>{{ $t("job.desc-16") }}</p>
          <h3>{{ $t("job.desc-01") }}</h3>
          <p>{{ $t("job.desc-17") }}</p>
          <div class="inside">
            <p>{{ $t("job.desc-18") }}</p>
            <p>{{ $t("job.desc-19") }}</p>
            <p>{{ $t("job.desc-20") }}</p>
          </div>
          <p>{{ $t("job.desc-21") }}</p>
          <div class="inside">
            <p>{{ $t("job.desc-22") }}</p>
            <p>{{ $t("job.desc-23") }}</p>
            <p>{{ $t("job.desc-24") }}</p>
            <p>{{ $t("job.desc-25") }}</p>
          </div>
          <p>{{ $t("job.desc-26") }}</p>
          <div class="inside">
            <p>{{ $t("job.desc-27") }}</p>
            <p>{{ $t("job.desc-28") }}</p>
            <p>{{ $t("job.desc-29") }}</p>
          </div>
          <p>{{ $t("job.desc-30") }}</p>
        </div>
        <br />
        <hr />
        <br />
        <div class="ad_t">
          <h2>{{ $t("job.open-position-03") }}</h2>
          <p>{{ $t("job.desc-31") }}</p>
          <h3>{{ $t("job.desc-01") }}</h3>
          <p>{{ $t("job.desc-32") }}</p>
          <p>{{ $t("job.desc-33") }}</p>
          <div class="inside">
            <p>{{ $t("job.desc-34") }}</p>
            <p>{{ $t("job.desc-35") }}</p>
            <p>{{ $t("job.desc-36") }}</p>
          </div>
          <p>{{ $t("job.desc-37") }}</p>
          <div class="inside">
            <p>{{ $t("job.desc-38") }}</p>

            <p>{{ $t("job.desc-39") }}</p>
            <p>{{ $t("job.desc-40") }}</p>
            <p>{{ $t("job.desc-41") }}</p>
          </div>
          <p>{{ $t("job.desc-42") }}</p>
          <div class="inside">
            <p>{{ $t("job.desc-43") }}</p>
            <p>{{ $t("job.desc-44") }}</p>
            <p>{{ $t("job.desc-45") }}</p>
          </div>
          <p>{{ $t("job.desc-46") }}</p>
        </div>
      </div>
    </div>
  </vue-if-bot>
</template>

<script>
export default {
  name: "Job",
  components: {
    VueIfBot: () => import("vue-if-bot"),
  },
  data() {
    return {}
  },
};
</script>

<style lang="scss">
.job__container {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
  .job__img {
    background-image: url("../assets/img/job.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-position: bottom;

    height: 550px;
    position: relative;

    .job__inside__el {
      border: 2px solid;
      border-color: #ffff;
      background-color: rgba(23, 71, 125, 0.61);
      width: 550px;
      height: 200px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      img {
        height: 200px;
        position: absolute;
        top: 0%;
        left: 50%;
        transform: translate(-50%, -50%);
      }

      .text {
        margin: 70px 30px;
        color: white;
        font-size: 16px;
        text-align: justify;
        opacity: 1;
        font-family: "Ubuntu", sans-serif;
        font-weight: 600;
        line-height: 1.5;
      }

      .title {
        margin: 70px 20px;
        color: white;
        font-size: 28px;
        text-align: center;
        opacity: 1;
        font-family: "Ubuntu", sans-serif;
        font-weight: 600;
        line-height: 1.6;
      }
    }

    @media (max-width: 1140px) {
      .job__inside__el {
        width: 250px;
      }
    }
  }

  .job__info {
    border-top: 2px solid #ffff;
    padding: 50px 194px 50px 136px;
    background-color: #17477d;
    .job__info_positions {
      display: flex;
      flex-direction: column;
      justify-items: center;
      justify-content: center;
      width: 50%;
      margin: 0 auto;

      div {
        display: flex;
        p {
          color: #ffff;
          font-size: 20px;
          padding-left: 10px;
          font-weight: 600;
          line-height: 1.5;
        }
        .icon {
          width: 15px;
          height: 15px;
          color: #ffff;
          align-self: center;
        }
      }
    }
  }

  @media (max-width: 1140px) {
    .job__info {
      padding: 20px;
    }
  }

  .job__adverts {
    padding: 50px 194px 50px 136px;

    h3 {
      font-size: 20px;
      padding-top: 20px;
      padding-bottom: 20px;
    }

    p {
      font-size: 16px;
      line-height: 1.5;
    }

    .inside {
      margin-left: 20px;
      margin-top: 20px;
      margin-bottom: 20px;
      font-weight: 600;
    }

    h2 {
      padding-bottom: 20px;
    }
  }

  @media (max-width: 1140px) {
    .job__adverts {
      padding: 20px;
    }
  }
}
</style>