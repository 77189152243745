<template>
  <vue-if-bot>
    <div class="ser__container">
      <div data-aos="flip-up" class="ser__img">
        <div class="ser__inside__el">
          <img src="@/assets/img/logo.png" alt="logo" />
          <h2 data-aos="zoom-in-down" class="title">
            {{ $t("services.title") }}
          </h2>
        </div>
      </div>
      <div class="ser__desc">
        <div class="desc">
          <div>
            <span>
              <svg class="icon" viewBox="0 0 512 512" id="svg-ca44">
                <path
                  d="m433.1 67.1-231.8 231.9c-6.2 6.2-16.4 6.2-22.6 0l-99.8-99.8-78.9 78.8 150.5 150.5c10.5 10.5 24.6 16.3 39.4 16.3 14.8 0 29-5.9 39.4-16.3l282.7-282.5z"
                  fill="#ffff"
                ></path>
              </svg>
              <router-link :to="{ query: { scr: 'integManagment' } }">{{
                $t("services.integManagment")
              }}</router-link>
            </span>
            <span>
              <svg class="icon" viewBox="0 0 512 512" id="svg-ca44">
                <path
                  d="m433.1 67.1-231.8 231.9c-6.2 6.2-16.4 6.2-22.6 0l-99.8-99.8-78.9 78.8 150.5 150.5c10.5 10.5 24.6 16.3 39.4 16.3 14.8 0 29-5.9 39.4-16.3l282.7-282.5z"
                  fill="#ffff"
                ></path>
              </svg>
              <router-link :to="{ query: { scr: 'quality' } }">{{
                $t("services.quality")
              }}</router-link>
            </span>
            <span>
              <svg class="icon" viewBox="0 0 512 512" id="svg-ca44">
                <path
                  d="m433.1 67.1-231.8 231.9c-6.2 6.2-16.4 6.2-22.6 0l-99.8-99.8-78.9 78.8 150.5 150.5c10.5 10.5 24.6 16.3 39.4 16.3 14.8 0 29-5.9 39.4-16.3l282.7-282.5z"
                  fill="#ffff"
                ></path>
              </svg>
              <router-link :to="{ query: { scr: 'umwel' } }">{{
                $t("services.umwel")
              }}</router-link>
            </span>
            <span>
              <svg class="icon" viewBox="0 0 512 512" id="svg-ca44">
                <path
                  d="m433.1 67.1-231.8 231.9c-6.2 6.2-16.4 6.2-22.6 0l-99.8-99.8-78.9 78.8 150.5 150.5c10.5 10.5 24.6 16.3 39.4 16.3 14.8 0 29-5.9 39.4-16.3l282.7-282.5z"
                  fill="#ffff"
                ></path>
              </svg>
              <router-link :to="{ query: { scr: 'itService' } }">{{
                $t("services.itService")
              }}</router-link>
            </span>
            <span>
              <svg class="icon" viewBox="0 0 512 512" id="svg-ca44">
                <path
                  d="m433.1 67.1-231.8 231.9c-6.2 6.2-16.4 6.2-22.6 0l-99.8-99.8-78.9 78.8 150.5 150.5c10.5 10.5 24.6 16.3 39.4 16.3 14.8 0 29-5.9 39.4-16.3l282.7-282.5z"
                  fill="#ffff"
                ></path>
              </svg>
              <router-link :to="{ query: { scr: 'geschaf' } }">{{
                $t("services.geschaf")
              }}</router-link>
            </span>
          </div>
          <div>
            <span>
              <svg class="icon" viewBox="0 0 512 512" id="svg-ca44">
                <path
                  d="m433.1 67.1-231.8 231.9c-6.2 6.2-16.4 6.2-22.6 0l-99.8-99.8-78.9 78.8 150.5 150.5c10.5 10.5 24.6 16.3 39.4 16.3 14.8 0 29-5.9 39.4-16.3l282.7-282.5z"
                  fill="#ffff"
                ></path>
              </svg>
              <router-link :to="{ query: { scr: 'information' } }">{{
                $t("services.information")
              }}</router-link>
            </span>
            <span>
              <svg class="icon" viewBox="0 0 512 512" id="svg-ca44">
                <path
                  d="m433.1 67.1-231.8 231.9c-6.2 6.2-16.4 6.2-22.6 0l-99.8-99.8-78.9 78.8 150.5 150.5c10.5 10.5 24.6 16.3 39.4 16.3 14.8 0 29-5.9 39.4-16.3l282.7-282.5z"
                  fill="#ffff"
                ></path>
              </svg>
              <router-link :to="{ query: { scr: 'datenschutz' } }">{{
                $t("services.datenschutz")
              }}</router-link>
            </span>
            <span>
              <svg class="icon" viewBox="0 0 512 512" id="svg-ca44">
                <path
                  d="m433.1 67.1-231.8 231.9c-6.2 6.2-16.4 6.2-22.6 0l-99.8-99.8-78.9 78.8 150.5 150.5c10.5 10.5 24.6 16.3 39.4 16.3 14.8 0 29-5.9 39.4-16.3l282.7-282.5z"
                  fill="#ffff"
                ></path>
              </svg>
              <router-link :to="{ query: { scr: 'risikoman' } }">{{
                $t("services.risikoman")
              }}</router-link>
            </span>
            <span>
              <svg class="icon" viewBox="0 0 512 512" id="svg-ca44">
                <path
                  d="m433.1 67.1-231.8 231.9c-6.2 6.2-16.4 6.2-22.6 0l-99.8-99.8-78.9 78.8 150.5 150.5c10.5 10.5 24.6 16.3 39.4 16.3 14.8 0 29-5.9 39.4-16.3l282.7-282.5z"
                  fill="#ffff"
                ></path>
              </svg>
              <router-link :to="{ query: { scr: 'energi' } }">{{
                $t("services.energi")
              }}</router-link>
            </span>
            <span>
              <svg class="icon" viewBox="0 0 512 512" id="svg-ca44">
                <path
                  d="m433.1 67.1-231.8 231.9c-6.2 6.2-16.4 6.2-22.6 0l-99.8-99.8-78.9 78.8 150.5 150.5c10.5 10.5 24.6 16.3 39.4 16.3 14.8 0 29-5.9 39.4-16.3l282.7-282.5z"
                  fill="#ffff"
                ></path>
              </svg>
              <router-link :to="{ query: { scr: 'interne' } }">{{
                $t("services.interne")
              }}</router-link>
            </span>
          </div>
        </div>
        <div class="desc">
          <p>
            {{ $t("services.finden") }}
            <span
              ><a href="mailto: office@itandsec.com">
                office@itandsec.com</a
              ></span
            >
          </p>
        </div>
      </div>

      <div class="sev__img__text">
        <div class="sections" data-aos="zoom-in-down">
          <section >
            <div ref="integManagment" id="integManagment">
              <h3>{{ $t("services.title-01") }}</h3>
              <p>{{ $t("services.integManagment-01") }}</p>
            </div>
          </section>
          <section>
            <img src="@/assets/img/quality.jpg" alt="pic" />
          </section>
        </div>
        <div class="sections" data-aos="zoom-in-down">
          <section class="section__bgr-img">
            <img src="@/assets/img/services-04.jpg" alt="pic" />
          </section>
          <section class="section__bgr">
            <div ref="quality">
              <h3>{{ $t("services.title-02") }}</h3>
              <p>{{ $t("services.quality-01") }}</p>
              <p class="inside-p">{{ $t("services.quality-02") }}</p>
              <p class="inside-p">{{ $t("services.quality-03") }}</p>
              <p class="inside-p">{{ $t("services.quality-04") }}</p>
              <p>{{ $t("services.quality-05") }}</p>
            </div>
          </section>
        </div>
      </div>
      <div class="ser__inside">
        <p data-aos="zoom-in-down">{{ $t("services.inside") }}</p>
      </div>
      <div data-aos="zoom-in-down" class="ser__description" ref="umwel">
        <h3>{{ $t("services.umwel-00") }}</h3>
        <p>{{ $t("services.umwel-01") }}</p>
        <p class="inside-p">{{ $t("services.umwel-02") }}</p>
        <p class="inside-p">{{ $t("services.umwel-022") }}</p>
        <p class="inside-p">{{ $t("services.umwel-03") }}</p>
        <p class="inside-p">{{ $t("services.umwel-04") }}</p>
        <p class="inside-p">{{ $t("services.umwel-05") }}</p>
        <p class="inside-p">{{ $t("services.umwel-06") }}</p>
        <p class="inside-p">{{ $t("services.umwel-07") }}</p>
        <p class="inside-p">{{ $t("services.umwel-08") }}</p>
        <p class="inside-p">{{ $t("services.umwel-09") }}</p>
        <p class="inside-p">{{ $t("services.umwel-10") }}</p>
        <p>{{ $t("services.umwel-11") }}</p>
      </div>
      <div data-aos="zoom-in-down" class="ser__description_section">
        <section>
          <img src="@/assets/img/services-03.jpg" alt="pic" />
        </section>
        <section>
          <div ref="itService">
            <h3>{{ $t("services.itService-01") }}</h3>
            <p>{{ $t("services.itService-02") }}</p>
          </div>
        </section>
      </div>
      <div data-aos="zoom-in-down" class="ser__inside sec" ref="geschaf">
        <h3>{{ $t("services.geschaf-01") }}</h3>
        <p>{{ $t("services.geschaf-02") }}</p>
      </div>
      <div data-aos="zoom-in-down" class="ser__columns" ref="information">
        <section class="blue">
          <h3>{{ $t("services.information-title") }}</h3>
          <p>{{ $t("services.information-text") }}</p>
        </section>
        <section class="grey" ref="datenschutz">
          <h3>{{ $t("services.datenschutz-title-01") }}</h3>
          <p>{{ $t("services.datenschutz-text-01") }}</p>
          <p class="inside">{{ $t("services.datenschutz-text-02") }}</p>
          <p class="inside">{{ $t("services.datenschutz-text-03") }}</p>
          <p class="inside">{{ $t("services.datenschutz-text-04") }}</p>
          <p class="inside">{{ $t("services.datenschutz-text-05") }}</p>
          <p class="inside">{{ $t("services.datenschutz-text-06") }}</p>
          <p class="inside">{{ $t("services.datenschutz-text-07") }}</p>
          <p class="inside">{{ $t("services.datenschutz-text-08") }}</p>
          <p class="inside">{{ $t("services.datenschutz-text-09") }}</p>
          <p>{{ $t("services.datenschutz-text-10") }}</p>
        </section>
        <section class="blue" ref="risikoman">
          <h3>{{ $t("services.risikoman-title") }}</h3>
          <p>{{ $t("services.risikoman-text") }}</p>
        </section>
      </div>
      <div data-aos="zoom-in-down" class="ser__before">
        <h3 ref="energi">{{ $t("services.energi-title") }}</h3>
        <div>
          <section>
            <p>{{ $t("services.energi-text") }}</p>
          </section>
          <section>
            <p class="inside">{{ $t("services.energi-text-01") }}</p>
            <p class="inside">{{ $t("services.energi-text-02") }}</p>
            <p class="inside">{{ $t("services.energi-text-03") }}</p>
            <p class="inside">{{ $t("services.energi-text-04") }}</p>
            <p class="inside">{{ $t("services.energi-text-05") }}</p>
            <p class="inside">{{ $t("services.energi-text-06") }}</p>
          </section>
        </div>
        <section>
          <p>{{ $t("services.energi-text-07") }}</p>
        </section>
      </div>

      <div data-aos="zoom-in-down" class="ser__before last">
        <img src="@/assets/img/services-02.jpg" alt="pic" />
        <div>
          <h3 ref="interne">{{ $t("services.interne-title") }}</h3>
          <p>{{ $t("services.interne-text") }}</p>
          <h3 ref="interne">{{ $t("services.interne-title-01") }}</h3>
          <p>{{ $t("services.interne-text-01") }}</p>
        </div>
      </div>
    </div>
  </vue-if-bot>
</template>

<script>
export default {
  name: "Services",
  components: {
    VueIfBot: () => import('vue-if-bot')
  },
  data() {
    return {};
  },
  methods: {
    scrollTo() {
      if (this.$route.query.scr) {
        const element = this.$refs[this.$route.query.scr];
        this.$nextTick(() => {
          element.scrollIntoView({ behavior: "smooth" });
        });
      }
    },
  },
  watch: {
    $route: { handler: "scrollTo" },
  },
};
</script>

<style lang="scss">
.ser__container {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
  .ser__img {
    background-image: url("../assets/img/ITandSEK_06.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-position: center;
    height: 550px;
    position: relative;

    .ser__inside__el {
      border: 2px solid;
      border-color: #ffff;
      background-color: rgba(23, 71, 125, 0.61);
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      img {
        height: 200px;
        position: absolute;
        top: 0%;
        left: 50%;
        transform: translate(-50%, -50%);
      }

      .title {
        margin: 70px 20px;
        color: white;
        font-size: 28px;
        text-align: center;
        opacity: 1;
        font-family: "Ubuntu", sans-serif;
        font-weight: 600;
        line-height: 1.6;
      }
    }
  }
  .ser__desc {
    border-top: 2px solid #ffff;
    display: flex;
    flex-direction: column;
    .desc {
      padding: 20px 194px 20px 136px;
      background-color: #17477d;
      display: flex;
      justify-content: center;
      gap: 50px;
      flex-shrink: 2;
      span {
        display: block;
        flex-basis: 40%;
        font-size: 16px;
        font-weight: 600;
        color: #0cbcf5;
        padding-bottom: 5px;
        line-height: 1.5;
        a {
          padding-left: 5px;
        }
        svg {
          width: 10px;
          height: 10px;
        }
      }
      p {
        font-size: 16px;
        color: #ffff;
        font-weight: 600;
        span {
          display: inline;
        }
      }
    }
  }

  @media all and (max-width: 1140px) {
    .ser__desc {
      .desc {
        display: initial;
        padding: 20px;
      }
    }
  }
  .sev__img__text {
    padding: 20px 194px 50px 136px;

    .sections {
      display: flex;
      font-size: 16px;
      section {
        flex-basis: 50%;
        div {
          padding: 0 30px 0 30px;
          h3 {
            padding: 30px 0;
          }
          p {
            text-align: justify;
          }
          .inside-p {
            margin: 8px;
            font-weight: 600;
          }
        }
        img {
          width: 100%;
          height: auto;
        }
      }
      .section__bgr {
        background-color: #e4e4f1;
        padding-bottom: 30px;
        div {
          scroll-margin-top: 100px;
        }
      }
      .section__bgr-img {
        display: flex;
        justify-content: center;
      }
    }
    .sections:nth-child(2) {
      margin-top: -5px;
    }

    #integManagment {
      scroll-margin: 100px;
    }
  }

  @media (max-width: 1140px) {
    .sev__img__text {
      padding: 20px;
      .sections {
        display: initial;
        section {
          div {
            padding: 20px;
            h3 {
              padding: 0 0 20px 0;
            }
            p {
              padding: 20px;
            }
          }
        }
      }
    }
  }

  .ser__inside {
    background-color: #17477d;
    p {
      text-align: center;
      font-size: 16px;
      padding: 60px 200px;
      color: #ffff;
      font-weight: 600;
    }

    @media all and (max-width: 1140px) {
      p {
        padding: 20px 20px;
      }
    }

    &.sec {
      background-color: #ffff;
      font-size: 16px;
      margin: 50px;
      scroll-margin-top: 100px;
      h3 {
        padding: 20px 150px;
      }
      p {
        padding: 0px 200px 20px 150px;
        color: #111111;
        font-weight: 500;
        text-align: justify;
      }
    }

    @media (max-width: 1140px) {
      &.sec {
        p,
        h3 {
          padding: 10px;
        }
      }
    }
  }

  .ser__description {
    font-size: 16px;
    padding: 0 194px 30px 136px;
    background-color: #e4e4f1;
    scroll-margin-top: 100px;
    h3 {
      padding: 30px 0;
    }
    .inside-p {
      margin: 8px;
      font-weight: 600;
      padding-left: 30px;
    }
  }

  @media (max-width: 1140px) {
    .ser__description {
      padding: 20px 20px;
    }
  }

  .ser__description_section {
    padding: 30px 194px 30px 136px;
    display: flex;
    background-color: #e4e4f1;

    section {
      display: flex;
      flex-basis: 50%;
      font-size: 16px;
      background-color: #17477d;
      color: #ffff;
      padding-bottom: 20px;

      img {
        width: 100%;
        height: auto;
      }
      div {
        scroll-margin-top: 100px;
        h3 {
          padding: 30px 20px;
        }

        p {
          text-align: justify;
          padding: 0 20px 20px 20px;
        }
      }
    }
  }

  .ser__before last {
     img {
        width: 40%;
        height: auto;
      }
  }

  @media (max-width: 1140px) {
    .ser__description_section {
      padding: 20px;
      display: flex;
      flex-direction: column;
      section {
        padding-bottom: 20px;
      }
    }
  }

  .ser__columns {
    padding: 0 194px 0 136px;
    display: flex;
    gap: 20px;
    font-size: 16px;
    background-color: #e4e4f1;
    scroll-margin-top: 100px;
    .blue {
      flex-basis: 30%;
      background-color: #17477d;
      color: #ffff;
      scroll-margin-top: 100px;
    }

    .grey {
      flex-basis: 60%;
      background-color: #e4e4f1;
      scroll-margin-top: 100px;

      .inside {
        font-weight: 600;
        padding: 5px 40px;
      }
    }

    p,
    h3 {
      padding: 20px;
    }

    h3 {
      text-transform: uppercase;
    }
  }

  @media (max-width: 1140px) {
    .ser__columns {
      flex-direction: column;
      padding: 20px;
      text-align: justify;
    }
  }

  .ser__before {
    padding: 30px 194px 30px 136px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    font-size: 16px;
    h3 {
      scroll-margin-top: 100px;
    }
    div {
      display: flex;
      gap: 20px;
      section {
        flex-basis: 50%;
      }

      .inside {
        font-weight: 600;
        font-size: 14px;
        text-align: start;
      }
    }
    p {
      text-align: justify;
    }

    &.last {
      padding: 30px 194px 30px 136px;
      display: flex;
      flex-direction: row;
      background-color: #e4e4f1;
      div {
        display: flex;
        flex-direction: column;
        flex-basis: 50%;
      }
      img {
        flex-basis: 30%;
        width: 50%;
        height: auto;
      }

      @media (max-width: 1140px) {
        &.last {
          padding: 20px;
          flex-direction: column;
          img {
            flex-basis: 100%;
            width: 100%;
          }
        }
      }
    }
  }

  @media (max-width: 1140px) {
    .ser__before {
      flex-direction: column;
      padding: 20px;
      text-align: justify;
    }
  }
}
</style>