<template>
  <vue-if-bot>
    <div class="home__container">
      <div data-aos="flip-up" class="home__img">
        <div class="home__inside__el">
          <img src="@/assets/img/logo.png" alt="logo" />
          <p data-aos="zoom-in-down" class="text">
            {{ $t("home.text-01") }}
          </p>
        </div>
      </div>

      <div class="home__text__el">
        <section class="home__text__el__clm">
          <p>{{ $t("home.text-02") }}</p>
        </section>
        <section>
          <img
            data-aos="zoom-in-down"
            style="width: 100%"
            src="@/assets/img/quality-logo.jpg"
            alt="logo"
          />
        </section>
        <section class="home__text__el__clm">
          <p>{{ $t("home.text-03") }}</p>
          <p>{{ $t("home.text-04") }}</p>
        </section>
      </div>

      <div class="home__text__el__img" data-aos="zoom-in-down">
        <img :src="set_image()" alt="pic" />
      </div>

      <div class="home__text__el__sng" data-aos="zoom-in-down">
        <div class="home__text__el__sng__ins">
          <p data-aos="zoom-in-down" class="text__sng">
            {{ $t("home.text-05") }}
          </p>
          <p data-aos="zoom-in-down" class="text__sng">
            {{ $t("home.text-06") }}
          </p>
        </div>
      </div>
    </div>
  </vue-if-bot>
</template>

<script>
export default {
  name: "Home",
  components: {
    VueIfBot: () => import("vue-if-bot"),
  },
  data() {
    return {};
  },
  methods: {
    set_image() {
      if (localStorage.locale === "de") {
        return require("../assets/img/home-graf-de.jpg");
      } else {
        return require("../assets/img/home-graf-en.jpg");
      }
    },
  },
};
</script>

<style lang="scss">
.home__container {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
  .home__img {
    background-image: url("../assets/img/home_img.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-position: center;
    height: 550px;
    position: relative;

    .home__inside__el {
      border: 2px solid;
      border-color: #ffff;
      background-color: rgba(23, 71, 125, 0.61);
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      img {
        height: 200px;
        position: absolute;
        top: 0%;
        left: 50%;
        transform: translate(-50%, -50%);
      }

      .text {
        margin: 70px 30px;
        color: white;
        font-size: 16px;
        text-align: justify;
        opacity: 1;
        font-family: "Ubuntu", sans-serif;
        font-weight: 600;
        line-height: 1.5;
      }

      @media (max-width: 1140px) {
        .text {
          width: 300px;
          font-size: 12px;
          margin: 60px 10px 10px 10px;
        }
      }
    }
  }
  .home__text__el {
    margin-top: 60px;
    display: flex;
    justify-content: center;
    gap: 30px;
    padding: 0 194px 50px 136px;

    .home__text__el__clm {
      color: #17477d;
      font-size: 14.5px;
      flex-basis: 47%;
      text-align: justify;
      p {
        padding-bottom: 20px;
      }
    }
  }

  @media (max-width: 1140px) {
    .home__text__el {
      flex-direction: column;
      padding: 20px;
      margin-top: 10px;

      .home__text__el__clm {
        p {
          padding-bottom: 0;
        }
      }
    }
  }

  .home__text__el__img {
    display: flex;
    justify-content: center;
    padding-bottom: 200px;
    padding: 0 194px 50px 136px;
    img {
      width: 75%;
      height: auto;
      margin: 0 auto;
    }
  }

  @media (max-width: 1140px) {
    .home__text__el__img {
      padding: 20px;
      img {
        width: 100%;
      }
    }
  }

  .home__text__el__sng {
    .home__text__el__sng__ins {
      background-color: #17477d;
      color: white;

      .text__sng {
        padding: 30px 194px 30px 136px;
        font-size: 16px;
        text-align: center;
      }

      @media (max-width: 1140px) {
        .text__sng {
          padding: 20px;
        }
      }
    }
  }
}
</style>