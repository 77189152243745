<template>
  <vue-if-bot>
    <div class="data__pr__container">
      <section class="data__pr__container__section">
        <h2 class="data__pr__title">{{ $t("data_protection.title") }}</h2>
        <p style="padding: 10px 0">{{ $t("data_protection.text-01") }}</p>
        <p style="padding: 10px 0">{{ $t("data_protection.text-0101") }}</p>
        <p style="padding: 10px 0">{{ $t("data_protection.text-0102") }}</p>

        <h3>{{ $t("data_protection.title-01") }}</h3>
        <p>{{ $t("data_protection.text-02") }}</p>
        <h3>{{ $t("data_protection.subtitle-01") }}</h3>
        <p>{{ $t("data_protection.text-03") }}</p>
        <h3>{{ $t("data_protection.subtitle-02") }}</h3>
        <p>{{ $t("data_protection.text-04") }}</p>
        <h3>{{ $t("data_protection.subtitle-03") }}</h3>
        <p>{{ $t("data_protection.text-05") }}</p>
        <h3>{{ $t("data_protection.subtitle-04") }}</h3>
        <p>{{ $t("data_protection.text-06") }}</p>
        <h3>{{ $t("data_protection.subtitle-05") }}</h3>
        <p>{{ $t("data_protection.text-07") }}</p>
        <h3>{{ $t("data_protection.subtitle-06") }}</h3>
        <p>{{ $t("data_protection.text-08") }}</p>
        <h3>{{ $t("data_protection.subtitle-07") }}</h3>
        <p>{{ $t("data_protection.text-09") }}</p>
        <h3>{{ $t("data_protection.subtitle-08") }}</h3>
        <p>{{ $t("data_protection.text-10") }}</p>
        <h3>{{ $t("data_protection.subtitle-09") }}</h3>
        <p>{{ $t("data_protection.text-11") }}</p>
        <h3>{{ $t("data_protection.subtitle-10") }}</h3>
        <p>{{ $t("data_protection.text-12") }}</p>
        <h3>{{ $t("data_protection.subtitle-11") }}</h3>
        <p>{{ $t("data_protection.text-13") }}</p>
        <h3>{{ $t("data_protection.title-02") }}</h3>
        <p>{{ $t("data_protection.desc-01") }}</p>
        <h3>{{ $t("data_protection.title-03") }}</h3>
        <p>{{ $t("data_protection.cookies") }}</p>
        <h3>{{ $t("data_protection.title-04") }}</h3>
        <p>{{ $t("data_protection.collection") }}</p>
        <br />
        <p>{{ $t("data_protection.collection-01") }}</p>
        <p>{{ $t("data_protection.collection-02") }}</p>
        <p>{{ $t("data_protection.collection-03") }}</p>
        <p>{{ $t("data_protection.collection-04") }}</p>
        <p>{{ $t("data_protection.collection-05") }}</p>
        <p>{{ $t("data_protection.collection-06") }}</p>
        <p>{{ $t("data_protection.collection-07") }}</p>
        <p>{{ $t("data_protection.collection-08") }}</p>
        <br />
        <p>{{ $t("data_protection.collection-09") }}</p>

        <h3>{{ $t("data_protection.title-05") }}</h3>
        <p>{{ $t("data_protection.contact") }}</p>

        <h3>{{ $t("data_protection.title-06") }}</h3>
        <p>{{ $t("data_protection.data_controller") }}</p>

        <h3>{{ $t("data_protection.title-07") }}</h3>
        <p class="bold">{{ $t("data_protection.desc-a") }}</p>
        <p class="space-bottom">{{ $t("data_protection.desc-a-text") }}</p>

        <p class="bold">{{ $t("data_protection.desc-b") }}</p>
        <p class="space-bottom">{{ $t("data_protection.desc-b-text") }}</p>

        <p class="bold">{{ $t("data_protection.desc-c") }}</p>
        <p class="space-bottom">{{ $t("data_protection.desc-c-text") }}</p>

        <p class="bold">{{ $t("data_protection.desc-d") }}</p>
        <p class="space-bottom">{{ $t("data_protection.desc-d-text") }}</p>

        <p class="bold">{{ $t("data_protection.desc-e") }}</p>
        <p class="space-bottom">{{ $t("data_protection.desc-e-text") }}</p>

        <p class="bold">{{ $t("data_protection.desc-f") }}</p>
        <p class="space-bottom">{{ $t("data_protection.desc-f-text") }}</p>

        <p class="bold">{{ $t("data_protection.desc-g") }}</p>
        <p class="space-bottom">{{ $t("data_protection.desc-g-text") }}</p>

        <p>{{ $t("data_protection.desc-g-text-supl") }}</p>
        <p class="space-bottom">
          {{ $t("data_protection.desc-g-text-supl-01") }}
        </p>
        <p>{{ $t("data_protection.desc-g-text-supl-02") }}</p>
        <br />
        <p>{{ $t("data_protection.desc-g-text-supl-03") }}</p>
        <br />
        <p class="bold">{{ $t("data_protection.desc-h") }}</p>
        <p class="space-bottom">{{ $t("data_protection.desc-h-text") }}</p>

        <p class="bold">{{ $t("data_protection.desc-i") }}</p>
        <p class="space-bottom">{{ $t("data_protection.desc-i-text") }}</p>

        <h3>{{ $t("data_protection.title-08") }}</h3>
        <p class="space-bottom">
          {{ $t("data_protection.data_protection_text") }}
        </p>

        <h3>{{ $t("data_protection.title-09") }}</h3>
        <p class="space-bottom">{{ $t("data_protection.legal_basis") }}</p>

        <h3>{{ $t("data_protection.title-10") }}</h3>
        <p class="space-bottom">{{ $t("data_protection.interest") }}</p>

        <h3>{{ $t("data_protection.title-11") }}</h3>
        <p class="space-bottom">{{ $t("data_protection.period") }}</p>

        <h3>{{ $t("data_protection.title-12") }}</h3>
        <p class="space-bottom">{{ $t("data_protection.provision") }}</p>

        <h3>{{ $t("data_protection.title-13") }}</h3>
        <p style="padding-bottom: 20px">
          {{ $t("data_protection.existence") }}
        </p>
      </section>
    </div>
  </vue-if-bot>
</template>

<script>

export default {
  name: "Data_Protection",
  components: {
    VueIfBot: () => import('vue-if-bot'),  
  },
  data() {
    return {}
  }
}
</script>

<style lang="scss">
.data__pr__container {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
  .data__pr__container__section {
    padding: 0 194px 0 136px;
    .data__pr__title {
      padding: 20px 0;
    }
    p {
      font-size: 16px;
      text-align: justify;
    }

    h3 {
      font-size: 18px;
      padding: 20px 0;
    }

    .bold {
      font-weight: 600;
    }

    .space-bottom {
      padding-bottom: 20px;
    }
  }

  @media (max-width: 1140px) {
    .data__pr__container__section {
      padding: 20px;
    }
  }
}
</style>