<template>
  <vue-if-bot>
    <header class="hdr">
      <router-link class="hdr__logo" to="/">
        <img src="@/assets/img/logo_big.png" alt="logo" to="/" />
      </router-link>
      <nav class="hdr__nv" :class="{ hdr__active: open }">
        <HeaderLocaleMobile v-if="open" class="mobile__lng" />
        <router-link :class="{ hdr__nv__lnk: open}" to="/" exact>
          <span>{{ $t("header.home") }}</span>
        </router-link>
        <router-link class="hdr__nv__lnk" to="/about">
          <span>{{ $t("header.about_us") }}</span>
        </router-link>
        <router-link class="hdr__nv__lnk" to="/services">
          <span>{{ $t("header.services") }}</span>
        </router-link>
        <router-link class="hdr__nv__lnk" to="/trainings">
          <span>{{ $t("header.trainings") }}</span>
        </router-link>
        <router-link class="hdr__nv__lnk" to="/solutions">
          <span>{{ $t("header.solutions") }}</span>
        </router-link>
        <router-link class="hdr__nv__lnk" to="/references">
          <span>{{ $t("header.references") }}</span>
        </router-link>
        <router-link class="hdr__nv__lnk" to="/contact">
          <span>{{ $t("header.contact") }}</span>
        </router-link>
        <HeaderLocale v-if="!open" />
      </nav>
      <div class="menu" @click="open = !open">
        <img src="@/assets/img/menu-close.svg" v-if="open" alt="close" />
        <img src="@/assets/img/menu-mobile.svg" v-else alt="mobile" />
      </div>
    </header>
  </vue-if-bot>
</template>

<script>
import HeaderLocale from "./HeaderLocale.vue";
import HeaderLocaleMobile from "./HeaderLocaleMobile.vue";

export default {
  name: "Header",
  components: {
    HeaderLocale,
    HeaderLocaleMobile,
    VueIfBot: () => import("vue-if-bot"),
  },
  data() {
    return {
      open: false,
    };
  },
};
</script>

<style lang="scss">
@import "@/scss/base.scss";

html body {
  padding-top: $header;
}

.hdr {
  align-items: center;
  backdrop-filter: blur(30px);
  -webkit-backdrop-filter: blur(30px);
  color: #17477d;
  display: flex;
  font-size: 15px;
  font-weight: 500;
  height: $header;
  justify-content: space-between;
  left: 0;
  padding: 0 194px 0 136px;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 10;
  background-color: #fff;
}
@media (max-width: 1140px) {
  .hdr {
    padding: 20px;
  }
}
.menu {
  display: none;
  cursor: pointer;
}

.hdr__logo {
  cursor: pointer;
  display: inline-flex;
  @include tr();
  @include noselect();
  img {
    height: 60px;
  }
}

.hdr__nv {
  align-items: center;
  display: flex;

  &.hdr__active {
    @include transform(translateX(0%));
  }
}

.hdr__nv__lnk {
  cursor: pointer;
  position: relative;
  @include tr();
  @include noselect();


  span {
    display: inline-block;
    padding: 0 3px;
    position: relative;
    z-index: 1;
    font-family: "Ubuntu", sans-serif;
  }

  &.active,
  &.router-link-exact-active {
    background-color: #17477d;
    color: #fff;
  }

  &:not(:first-child) {
    margin-left: 24px;
  }

  &::before {
    background-color: #17477d;
    color: #fff;
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    transition: inherit;
    width: 0;
  }
  &:hover {
    color: #fff;
    &::before {
      width: 100%;
    }
  }
}

@media (max-width: 1140px) {
  .hdr__lg {
    display: flex;
    left: 3vh;
    position: absolute;
  }

  .menu {
    display: block;
    position: absolute;
    right: 1.5vh;
  }

  .hdr__nv {
    align-items: center;
    backdrop-filter: blur(30px);
    -webkit-backdrop-filter: blur(30px);
    background-color: rgba($color: #17477d, $alpha: 0.9);
    box-shadow: inset 0px 56px 39px #00000029;
    display: flex;
    flex-direction: column;
    position: absolute;
    height: 95vh;
    right: 0px;
    top: 13vh;
    width: 100%;
    @include transform(translateX(100%));
    @include tr();
    &.hdr__active {
      @include transform(translateX(0%));
    }
  }
  .hdr__nv__lnk {
    border-radius: 10px;
    border: 1px solid #ffffff;
    backdrop-filter: blur(30px);
    background-color: rgba($color: #ffffff, $alpha: 0.3);
    -webkit-backdrop-filter: blur(30px);
    top: 20vh;
    padding: 15px;
    margin-top: 10px;
    width: 90%;
    text-align: center;
  
    &:not(:first-child) {
      margin-left: 0px;
    }

    &::before {
      display: none;
    }
    &:hover {
      &::before {
        width: 100%;
      }
    }
  }
}
</style>
