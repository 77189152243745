<template>
  <vue-if-bot>
    <div class="im__container">
      <section class="im__container__section">
        <h2 class="im__title">IMPRESSUM</h2>
        <p>NenConsulting IT and Security GmbH</p>
        <p>Mühlenstrasse 8a</p>
        <p>14167 Berlin</p>
        <p>{{ $t("impressum.country") }}</p>
        <p>{{ $t("impressum.phone") }}</p>
        <address>
          E-mail:<a href="mailto: office@itandsec.com"> office@itandsec.com</a>
        </address>
        <br />
        <div class="im__container__div">
          <p>{{ $t("impressum.im__container__div1") }}</p>
          <br />
          <p>{{ $t("impressum.im__container__div2") }}</p>
          <br />
          <p>{{ $t("impressum.im__container__div3") }}</p>
          <br />
          <p>{{ $t("impressum.im__container__div4") }}</p>
          <br />
          <p>{{ $t("impressum.im__container__div5") }}</p>
          <br />
          <p>{{ $t("impressum.im__container__div6") }}</p>
          <br />
          <p>{{ $t("impressum.im__container__div7") }}</p>
          <br />
          <p>{{ $t("impressum.im__container__div8") }}</p>
        </div>
      </section>

      <section class="im__container__section">
        <br />
        <p>
          {{ $t("impressum.text")
          }}<a href="http://ec.europa.eu/odr" target="_blank">
            http://ec.europa.eu/odr</a
          >{{ $t("impressum.text-01") }}
          <a href="mailto: office@itandsec.com "> office@itandsec.com</a
          >{{ $t("impressum.text-011") }}
        </p>
        <br />

        <p>{{ $t("impressum.text-02") }}</p>
        <p>{{ $t("impressum.text-03") }}</p>
        <br />
        <p>{{ $t("impressum.text-04") }}</p>
        <p>{{ $t("impressum.text-05") }}</p>
        <br />
        <p>{{ $t("impressum.text-06") }}</p>
        <p>{{ $t("impressum.text-07") }}</p>
        <p>{{ $t("impressum.text-08") }}</p>
        <br />
        <p>{{ $t("impressum.text-09") }}</p>
        <p>
          {{ $t("impressum.text-10")
          }}<a
            href="http://www.impressum-recht.de/impressum-generator/"
            target="_blank"
          >
            Impressum Generator von Impressum-Recht.de</a
          >
        </p>
        <br />
        <br />
      </section>
    </div>
  </vue-if-bot>
</template>

<script>
export default {
  name: "Impressum",
  components: {
    VueIfBot: () => import("vue-if-bot"),
  },
  data() {
    return {}
  },
};
</script>

<style lang="scss">
.im__container {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
  background-color: #e4e4f1;
  .im__container__section {
    padding: 0 194px 0 136px;
    .im__title {
      padding: 30px 0;
    }
    p {
      font-size: 16px;
      text-align: justify;
    }
    address {
      font-size: 16px;
      text-decoration: none;
    }
    h3 {
      font-size: 18px;
      padding-top: 30px;
    }
    a {
      color: #0cbcf5;
    }

    .im__container__div {
      background-color: #e4e4f1;
      padding: 10px;
      width: 50%;

      &:hover {
        background-color: #fff;
      }
    }
  }

  @media (max-width: 1140px) {
    .im__container__section {
      padding: 20px;

      .im__container__div {
        background-color: #e4e4f1;
        width: 100%;
      }
    }
  }
}
</style>