<template>
  <vue-if-bot>
    <div class="philo__container">
      <div data-aos="flip-up" class="philo__img">
        <div class="philo__inside__el">
          <img src="@/assets/img/logo.png" alt="logo" />
          <h2 class="title">
            {{ $t("philosophy.title") }}
          </h2>
        </div>
      </div>
      <div data-aos="zoom-in-down" class="philo__desc">
        <h3>{{ $t("philosophy.description") }}</h3>
      </div>
    </div>
  </vue-if-bot>
</template>

<script>
export default {
  name: "Philosophy",
  components: {
    VueIfBot: () => import("vue-if-bot"),
  },
  data() {
    return {}
  },
};
</script>


<style lang="scss">
.philo__container {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
  .philo__img {
    background-image: url("../assets/img/philo-03.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-position: center;
    height: 600px;
    position: relative;

    .philo__inside__el {
      border: 2px solid;
      border-color: #ffff;
      background-color: rgba(23, 71, 125, 0.61);
      width: 550px;
      height: 200px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      img {
        height: 200px;
        position: absolute;
        top: 0%;
        left: 50%;
        transform: translate(-50%, -50%);
      }

      .title {
        margin: 70px 20px;
        color: white;
        font-size: 24px;
        text-align: center;
        opacity: 1;
        font-family: "Ubuntu", sans-serif;
        font-weight: 600;
        line-height: 1.6;
      }
    }

    @media (max-width: 1140px) {
      .philo__inside__el {
        width: 300px;
        height: 200px;
      }
    }
  }

  .philo__desc {
    background-color: #17477d;
    border-top: 2px solid #ffff;
    height: 300px;
    display: flex;
    justify-content: center;
    justify-items: center;

    h3 {
      text-align: center;
      color: #ffff;
      padding: 70px;
      align-items: center;
      font-size: 18px;
      width: 70%;
    }

    @media all and (max-width: 1140px) {
      h3 {
        width: 80%;
        padding: 45px;
        font-size: 14px;
      }
    }
  }
}
</style>