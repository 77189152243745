<template>
  <vue-if-bot>
    <div class="ref__container">
      <div data-aos="flip-up" class="ref__img">
        <div class="ref__inside__el">
          <img src="@/assets/img/logo.png" alt="logo" />
          <h2 data-aos="zoom-in-down" class="title">
            {{ $t("references.title") }}
          </h2>
        </div>
      </div>
      <div data-aos="zoom-in-down" class="ref__desc">
        <div>
          <h3>{{ $t("references.description") }}</h3>
          <ul>
            <li>{{ $t("references.desc-01") }}</li>
            <li>{{ $t("references.desc-02") }}</li>
            <li>{{ $t("references.desc-03") }}</li>
            <li>{{ $t("references.desc-04") }}</li>
            <li>{{ $t("references.desc-05") }}</li>
            <li>{{ $t("references.desc-06") }}</li>
          </ul>
        </div>
      </div>

      <div class="branding__wrap">
        <div class="title__desc">
          <h3>{{ $t("references.title-01") }}</h3>
          <p>{{ $t("references.paragraf") }}</p>
        </div>

        <div class="branding__logo">
          <section data-aos="zoom-in-down">
              <div class="image__logo">
                <img src="@/assets/img/logo/pic-02.jpg" alt="logo" />
              </div>
              <div class="image__logo">
                <img src="@/assets/img/logo/pic-01.jpg" alt="logo" />
              </div>
             
              <div class="image__logo">
                <img src="@/assets/img/logo/pic-04.jpg" alt="logo" />
              </div>
              <div class="image__logo">
                <img src="@/assets/img/logo/pic-15.jpg" alt="logo" />
              </div>
               <div class="image__logo">
                <img src="@/assets/img/logo/pic-16.png" alt="logo" />
              </div>
          </section>
          <section data-aos="zoom-in-down">
              <div class="image__logo">
                <img src="@/assets/img/logo/pic-07.png" alt="logo" />
              </div>
              <div class="image__logo">
                <img src="@/assets/img/logo/pic-06.jpg" alt="logo" />
              </div>

              <div class="image__logo">
                <img src="@/assets/img/logo/pic-19.jpg" alt="logo" />
              </div>
              <div class="image__logo">
                <img src="@/assets/img/logo/pic-09.jpg" alt="logo" />
              </div>
              <div class="image__logo">
                <img src="@/assets/img/logo/pic-03.png" alt="logo" />
              </div>
          </section>
          <section data-aos="zoom-in-down">
              <div class="image__logo">
                <img src="@/assets/img/logo/pic-11.jpg" alt="logo" />
              </div>
              <div class="image__logo">
                <img src="@/assets/img/logo/pic-12.jpg" alt="logo" />
              </div>
              <div class="image__logo">
                <img src="@/assets/img/logo/pic-13.jpg" alt="logo" />
              </div>
              <div class="image__logo">
                <img src="@/assets/img/logo/pic-10.jpg" alt="logo" />
              </div>
              <div class="image__logo">
                <img src="@/assets/img/logo/pic-21.jpg" alt="logo" />
              </div>
          </section>
           <section data-aos="zoom-in-down">
            <div class="image__logo">
                <img src="@/assets/img/logo/pic-18.jpg" alt="logo" />
            </div>
            <div class="image__logo">
                <img src="@/assets/img/logo/pic-22.jpg" alt="logo" />
            </div>
            <div class="image__logo">
                <img src="@/assets/img/logo/pic-14.jpg" alt="logo" />
            </div>
              <div class="image__logo" style="visibility: hidden;">
                <img src="@/assets/img/logo/pic-10.jpg" alt="logo" />
            </div>
              <div class="image__logo" style="visibility: hidden;">
                <img src="@/assets/img/logo/pic-10.jpg" alt="logo" />
            </div>
            </section>
        </div>
        <p>{{ $t("references.desc-07") }}</p>
      </div>
    </div>
  </vue-if-bot>
</template>

<script>
export default {
  name: "References",
  components: {
    VueIfBot: () => import("vue-if-bot"),
  },
  data() {
    return {}
  },
};
</script>

<style lang="scss">
.ref__container {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
  .ref__img {
    background-image: url("../assets/img/references.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-position: center;
    height: 600px;
    position: relative;

    .ref__inside__el {
      border: 2px solid;
      border-color: #ffff;
      background-color: rgba(23, 71, 125, 0.61);
      width: 550px;
      height: 200px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      img {
        height: 200px;
        position: absolute;
        top: 0%;
        left: 50%;
        transform: translate(-50%, -50%);
      }

      .title {
        margin: 70px 20px;
        color: white;
        font-size: 28px;
        text-align: center;
        opacity: 1;
        font-family: "Ubuntu", sans-serif;
        font-weight: 600;
        line-height: 1.6;
      }
    }

    @media (max-width: 1140px) {
      .ref__inside__el {
        width: 300px;
        height: 200px;
      }
    }
  }

  .ref__desc {
    background-color: #17477d;
    border-top: 2px solid #ffff;
    display: flex;

    div {
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding-bottom: 30px;
    }

    h3 {
      text-align: center;
      color: #ffff;
      text-align: center;
      font-size: 24px;
      width: 100%;
      padding-top: 40px;
      padding-bottom: 20px;
    }

    ul {
      margin: 0 auto;
      li {
        color: #ffff;
        padding-bottom: 10px;
        font-size: 18px;
        width: 100%;
      }
    }

    @media (max-width: 1140px) {
      h3 {
        width: 80%;
        padding: 45px;
        font-size: 16px;
      }
      li {
        width: 70%;
      }
    }
  }

  .branding__wrap {
    padding: 50px 194px 20px 136px;
    .title__desc {
      h3 {
        padding: 20px 0;
        margin: 0 auto;
        font-size: 34px;
        text-align: center;
        color: #17477d;
      }
    }

    .branding__logo {
      display: flex;
      flex-direction: column;
      padding: 30px 0;
      gap: 80px;
      section {
        display: flex;
        justify-content: space-between;
        div {
          width: 140px;
          height: 100px;

          img {
            width: 100%;
            height: auto;
          }
          @media (max-width: 1140px) {
            img {
              width: 70%;
            }
          }
        }
      }
    }

    p {
      padding-bottom: 20px;
      font-size: 18px;
      text-align: center;
    }
  }

  @media (max-width: 1140px) {
    .branding__wrap {
      padding: 20px;
      .branding__logo {
        display: initial;
      }
      p {
        padding: 20px;
      }
    }
  }
}
</style>