<template>
  <div id="app">
    <vue-if-bot>
      <Header />
      <transition name="slide" mode="out-in">
        <router-view></router-view>
      </transition>
      <transition name="fade">
        <div id="pagetop" class="fixed" v-show="scY > 300" @click="toTop">
          <span class="go_to_top">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="100"
              height="100"
              viewBox="0 0 34 34"
              fill="none"
              stroke="#ffff"
              stroke-width="1"
              stroke-linecap="square"
              stroke-linejoin="arcs"
            >
              <path d="M18 15l-6-6-6 6" />
            </svg>
          </span>
        </div>
      </transition>
      <Footer />
    </vue-if-bot>
  </div>
</template>

<script>
import Header from "./components/Header.vue";
import Footer from "./components/Footer.vue";

export default {
  name: "App",
  components: {
    VueIfBot: () => import('vue-if-bot'),
    Header,
    Footer,
  },
  data() {
    return {
      scTimer: 0,
      scY: 0,
    };
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
    window.process = {
      env: {
        NODE_ENV: 'development'
      }
    }
  },
  methods: {
    handleScroll: function () {
      if (this.scTimer) return;
      this.scTimer = setTimeout(() => {
        this.scY = window.scrollY;
        clearTimeout(this.scTimer);
        this.scTimer = 0;
      }, 100);
    },
    toTop: function () {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
  },
};
</script>

<style lang="scss">
@import "@/scss/reset.scss";

@font-face {
  font-family: "Ubuntu";
  src: url("./assets/fonts/Ubuntu-Light.woff2") format("woff2"),
    url("./assets/fonts/Ubuntu-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Ubuntu";
  src: url("./assets/fonts/Ubuntu-Italic.woff2") format("woff2"),
    url("./assets/fonts/Ubuntu-Italic.woff") format("woff");
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Ubuntu";
  src: url("./assets/fonts/Ubuntu-LightItalic.woff2") format("woff2"),
    url("./assets/fonts/Ubuntu-LightItalic.woff") format("woff");
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Ubuntu";
  src: url("./assets/fonts/Ubuntu-MediumItalic.woff2") format("woff2"),
    url("./assets/fonts/Ubuntu-MediumItalic.woff") format("woff");
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Ubuntu";
  src: url("./assets/fonts/Ubuntu-BoldItalic.woff2") format("woff2"),
    url("./assets/fonts/Ubuntu-BoldItalic.woff") format("woff");
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Ubuntu";
  src: url("./assets/fonts/Ubuntu-Medium.woff2") format("woff2"),
    url("./assets/fonts/Ubuntu-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Ubuntu";
  src: url("./assets/fonts/Ubuntu-Bold.woff2") format("woff2"),
    url("./assets/fonts/Ubuntu-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Ubuntu";
  src: url("./assets/fonts/Ubuntu-Regular.woff2") format("woff2"),
    url("./assets/fonts/Ubuntu-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
#app {
  color: #111111;
  font-family: "Ubuntu", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  font-family: "Ubuntu", Helvetica, Arial, sans-serif;
  line-height: 1.5;
}

h2 {
  font-size: 36px;
  line-height: 40px;
}

.fixed {
  position: absolute;
  right: 10px;
  bottom: 30px;
  overflow: hidden;

  .go_to_top {
    height: 70px;
    width: 70px;
    border-radius: 50%;
    display: block;
    background-color: rgb(205, 205, 205);
  }
}

@media (max-width: 1140px) {
  .fixed {
    display: none;
  }
}

.slide-enter-active {
  animation: slide-in 300ms ease-out forwards;
}

.slide-leave-active {
  animation: slide-out 300ms ease-out forwards;
}

@keyframes slide-in {
  from {
    transform: translateY(-30px);
    opacity: 0;
  }

  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slide-out {
  from {
    transform: translateY(0);
    opacity: 1;
  }

  to {
    transform: translateY(-30px);
    opacity: 0;
  }
}
</style>
