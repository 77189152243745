import Vue from 'vue'
import App from './App.vue'
import router from './router'
import VueI18n from 'vue-i18n'
import * as VueGoogleMaps from 'vue2-google-maps'
import AOS from 'aos';
import 'aos/dist/aos.css'

Vue.use(VueI18n);

Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyCOFCxpoyjPcMTzcFNcxtcZ3vWjHk045tM',
    libraries: "places"
  },
});


const messages = {
  de: require('./locale/de.json'),
  en: require('./locale/en.json'),
}

const i18n = new VueI18n({
  locale: 'en',
  messages
})

const savedLocale = localStorage.getItem('locale');

if (!savedLocale) {
  i18n.locale = 'de'
} else {
  i18n.locale = savedLocale;
}

Vue.config.productionTip = false;

new Vue({
  i18n,
  router,
  render: h => h(App),
  mounted() {
    AOS.init()
  }
}).$mount('#app')
