<template>
  <vue-if-bot>
    <div class="sol__container">
      <div data-aos="flip-up" class="sol__img">
        <div class="sol__inside__el">
          <img src="@/assets/img/logo.png" alt="logo" />
          <h2 data-aos="zoom-in-down" class="title">
            {{ $t("solution.title") }}
          </h2>
        </div>
      </div>
      <div data-aos="zoom-in-down" class="sol__desc">
        <h3>{{ $t("solution.description") }}</h3>
      </div>
    </div>
  </vue-if-bot>
</template>

<script>
export default {
  name: "Solutions",
  components: {
    VueIfBot: () => import("vue-if-bot"),
  },
  data() {
    return {}
  },
};
</script>

<style lang="scss">
.sol__container {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
  .sol__img {
    background-image: url("../assets/img/solutions.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-position: center;
    height: 550px;
    position: relative;

    .sol__inside__el {
      border: 2px solid;
      border-color: #ffff;
      background-color: rgba(23, 71, 125, 0.61);
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      img {
        height: 200px;
        position: absolute;
        top: 0%;
        left: 50%;
        transform: translate(-50%, -50%);
      }

      .text {
        margin: 70px 30px;
        color: white;
        font-size: 16px;
        text-align: justify;
        opacity: 1;
        font-family: "Ubuntu", sans-serif;
        font-weight: 600;
        line-height: 1.5;
      }

      .title {
        margin: 70px 20px;
        color: white;
        font-size: 28px;
        text-align: center;
        opacity: 1;
        font-family: "Ubuntu", sans-serif;
        font-weight: 600;
        line-height: 1.6;
      }
    }
  }

  .sol__desc {
    background-color: #17477d;
    display: flex;
    justify-content: center;
    justify-items: center;
    border-top: 2px solid #ffff;

    h3 {
      color: #ffff;
      text-align: center;
      padding: 60px;
      align-items: center;
      font-size: 18px;
      width: 70%;
    }

    @media (max-width: 1140px) {
      h3 {
        width: 90%;
        padding: 20px;
        font-size: 16px;
        text-align: justify;
      }
    }
  }
}
</style>