<template>
    <footer class="ftr">
      <vue-if-bot>
      <div class="ftr__right">
        <nav>
          <router-link class="ftr__nv__lnk" to="/philosophy">
            <span>{{ $t("footer.philosophy") }}</span>
          </router-link>
          <router-link class="ftr__nv__lnk" to="/data_protection">
            <span>{{ $t("footer.data_protection") }}</span>
          </router-link>
          <router-link class="ftr__nv__lnk" to="/impressum">
            <span>{{ $t("footer.impressum") }}</span>
          </router-link>
          <router-link class="ftr__nv__lnk" to="/job">
            <span>{{ $t("footer.job") }}</span>
          </router-link>
          <router-link class="ftr__nv__lnk" to="/blog">
            <span>{{ $t("footer.blog") }}</span>
          </router-link>
        </nav>
        <span class="description">
          © Copyright 2022 NenConsulting IT and Security GmbH. All Rights
          Reserved
          <br />
        </span>
        <div class="icon">
          <span class="icon_span">
            <a href="https://www.linkedin.com/company/itandsec/about/">
              <img src="@/assets/icon/linkedin.png" alt="icon" />
            </a>
          </span>
        </div>
      </div>
      </vue-if-bot>
    </footer>
</template>

<script>
export default {
  components: {
     VueIfBot: () => import('vue-if-bot')
  },
  data() {
    return {};
  },
};
</script>

<style lang="scss">
@import "@/scss/base.scss";
.ftr {
  color: #ffffff;
  background-color: #333333;
  padding: 50px 194px 50px 136px;
  display: flex;
  justify-content: center;
  gap: 100px;

  .ftr__logo {
    cursor: pointer;
    display: inline-flex;
    padding-top: 30px;
    @include tr();
    @include noselect();
    img {
      height: 50px;
    }
  }

  @media (max-width: 1140px) {
    .ftr__logo {
      padding: 0px;
      margin: 0 auto;
    }
  }

  .ftr__right {
    padding-top: 30px;
    flex-direction: column;

    span {
      font-size: 15px;
    }

    .icon {
      display: flex;
      justify-content: center;
    }

    nav {
      display: flex;
      padding-bottom: 20px;

      .ftr__nv__lnk {
        cursor: pointer;
        position: relative;
        @include tr();
        @include noselect();

        span {
          display: inline-block;
          padding: 0 3px;
          position: relative;
          z-index: 1;
          font-family: "Ubuntu", sans-serif;
          font-weight: 500;
          font-size: 15px;
        }

        &.active,
        &.router-link-exact-active {
          background-color: #fff;
          color: #333333;
        }

        &:not(:first-child) {
          margin-left: 24px;
        }

        @media (max-width: 1140px) {
          &:not(:first-child) {
            margin-left: 0;
          }
        }

        &::before {
          background-color: #fff;
          color: #333333;
          content: "";
          height: 100%;
          left: 0;
          position: absolute;
          transition: inherit;
          width: 0;
        }
        &:hover {
          color: #333333;
          &::before {
            width: 100%;
          }
        }
      }
    }

    @media (max-width: 1140px) {
      nav {
        flex-direction: column;
      }
    }
  }

  @media (max-width: 1140px) {
    .ftr__right {
      flex-direction: row;
      margin: 0 auto;
    }
  }

  .icon {
    padding-top: 30px;

    img {
      width: 40px;
      height: 40px;
    }
  }
}

@media (max-width: 1140px) {
  .ftr {
    padding: 20px;
    flex-direction: column;
    gap: 10px;
  }
}
</style>












